import { NumberInput, SelectInput, TextInput } from "@zipou/front_tools"
import { CameraAudioCodecEnum, CameraCodecEnum, CameraInput, CameraProtocolEnum, CameraStreamingTypeEnum } from "model"

type CameraFormInputProps = {
  camera: CameraInput,
  errors: any,
  onChange: (camera: CameraInput) => void,
}

export const CameraForm = ({ camera, errors, onChange }: CameraFormInputProps) => {

  return <>
    <TextInput label="Nom" id="name" value={camera?.name || ""} errors={errors} onChange={(v) => {
      onChange({
        ...camera,
        name: v,
      })
    }} />
    <TextInput label="Desc" id="description" value={camera?.description || ""} errors={errors} onChange={(v) => {
      onChange({
        ...camera,
        description: v,
      })
    }} />
    <TextInput label="Host" id="host" value={camera?.host || ""} errors={errors} onChange={(v) => {
      onChange({
        ...camera,
        host: v,
      })
    }} />
    <TextInput label="User" id="username" value={camera?.username || ""} errors={errors} onChange={(v) => {
      onChange({
        ...camera,
        username: v,
      })
    }} />
    <TextInput label="Password" id="password" value={camera?.password || ""} errors={errors} onChange={(v) => {
      onChange({
        ...camera,
        password: v,
      })
    }} />
    <TextInput label="path" id="path" value={camera?.path || ""} errors={errors} onChange={(v) => {
      onChange({
        ...camera,
        path: v,
      })
    }} />
    <NumberInput label="retentionDays" id="retentionDays" value={`${camera?.retentionDays}` || ""} errors={errors} onChange={(_, v) => {
      onChange({
        ...camera,
        retentionDays: v,
      })
    }} />
    <NumberInput label="motionDetectThresold" id="motionDetectThresold" value={`${camera?.motionDetectThresold}` || ""} errors={errors} onChange={(_, v) => {
      onChange({
        ...camera,
        motionDetectThresold: v,
      })
    }} />
    <NumberInput label="port" id="port" value={`${camera?.port}` || ""} errors={errors} onChange={(_, v) => {
      onChange({
        ...camera,
        port: v,
      })
    }} />
    <SelectInput
      choiceList={[{ id: CameraProtocolEnum.PROTOCOL_RTP, label: "RTP" }, { id: CameraProtocolEnum.PROTOCOL_RTSP, label: "RTSP" }]}
      label="Protocol" id="protocol" value={camera?.protocol || ""}
      errors={errors}
      onChange={(v) => {
        onChange({
          ...camera,
          protocol: v as CameraProtocolEnum,
        })
      }}
    />

    <SelectInput
      choiceList={[{ id: CameraStreamingTypeEnum.DASH, label: "DASH" }, { id: CameraStreamingTypeEnum.HLS, label: "HLS" }]}
      label="StreamingType" id="streamingType" value={camera?.streamingType || ""}
      errors={errors}
      onChange={(v) => {
        onChange({
          ...camera,
          streamingType: v as CameraStreamingTypeEnum,
        })
      }}
    />
    <SelectInput
      choiceList={[{ id: CameraCodecEnum.CODEC_H264, label: "H264" }, { id: CameraCodecEnum.CODEC_HEVC, label: "HEVC" }]}
      label="Codec" id="codec" value={camera?.codec || ""}
      errors={errors}
      onChange={(v) => {
        onChange({
          ...camera,
          codec: v as CameraCodecEnum,
        })
      }}
    />

    <SelectInput
      choiceList={[{ id: CameraAudioCodecEnum.CODEC_COPY, label: "COPY" }, { id: CameraAudioCodecEnum.CODEC_NO_AUDIO, label: "NO_AUDIO" }, { id: CameraAudioCodecEnum.CODEC_TRANSCODE_ACC, label: "Trancode to ACC" }]}
      label="Audio Codec" id="codecAudio" value={camera?.codecAudio || ""}
      errors={errors}
      onChange={(v) => {
        onChange({
          ...camera,
          codecAudio: v as CameraAudioCodecEnum,
        })
      }}
    />
  </>
}