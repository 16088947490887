export type Maybe<T> = T | null;
export type InputMaybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  JSONObject: any;
  JSON: any;
};

export type Query = {
  __typename?: 'Query';
  search: Array<SearchResult>;
  userProfile?: Maybe<User>;
  userGet?: Maybe<User>;
  authenticated: Scalars['Boolean'];
  userList: Array<User>;
  userRoleList: Array<Scalars['String']>;
  pictureList?: Maybe<PictureListResult>;
  pictureListByGuestId?: Maybe<Array<Picture>>;
  guestGet?: Maybe<Guest>;
  guestList?: Maybe<Array<Guest>>;
  guestSearch?: Maybe<Array<Guest>>;
  customerList: CustomerCursorResult;
  customerGet: Customer;
  orderList?: Maybe<OrderCursorList>;
  orderGet?: Maybe<Order>;
  paymentGetLast: Array<Payment>;
  invoiceList: InvoiceCursorList;
  invoiceGet: Invoice;
  invoiceStatusList: Array<Scalars['String']>;
  contractList: Array<Contract>;
  phoneCallListByPhoneConfigIdAndToken?: Maybe<PhoneCallListResult>;
  phoneCallCountMissed?: Maybe<Scalars['Float']>;
  phoneCallGetByPhoneConfigIdAndToken?: Maybe<PhoneCall>;
  phoneSequenceGet?: Maybe<PhoneSequence>;
  phoneSipLineTypeList: Array<Scalars['String']>;
  phoneConfigGetByIdAndToken: PhoneConfig;
  phoneConfigGet: PhoneConfig;
  phoneConfigListAll: Array<PhoneConfig>;
  phoneConfigList: Array<PhoneConfig>;
  phoneSipPeerStatus: Scalars['Boolean'];
  phoneHardGet: PhoneHard;
  phoneHardStatus?: Maybe<Scalars['Boolean']>;
  phoneHardTypeList: Array<Scalars['String']>;
  phoneHardSoftKeyConfigGetByType: SoftKeyConfig;
  metricsGetRecurentCA: Scalars['Float'];
  metricsGetCA: MetricsCAResult;
  itemSearch: Array<Item>;
  itemList: Array<Item>;
  itemGet: Item;
  notificationList: Array<Notification>;
  cardList: Array<Card>;
  contactTypeList: Array<Scalars['String']>;
  contactTitleList: Array<Scalars['String']>;
  contactGet: Contact;
  billingOperationLast: Scalars['Date'];
  bankAccountListByCustomerId?: Maybe<Array<BankAccount>>;
  serverGetByIdAndToken: Server;
  serverList: Array<Server>;
  serverListFull?: Maybe<Array<Server>>;
  serverGet: Server;
  hostingConfigGet?: Maybe<HostingConfig>;
  hostingConfigList?: Maybe<Array<HostingConfig>>;
  firewallGetByServerId: Firewall;
  backupConfigList: Array<BackupConfig>;
  phoneQueueGet?: Maybe<PhoneQueue>;
  clusterList: Array<Cluster>;
  clusterGet: Cluster;
  clusterRoleList?: Maybe<Array<ClusterRole>>;
  clusterRoleTypeList: Array<Scalars['String']>;
  clusterRoleGet: ClusterRole;
  clusterConfigPrometheusList: Scalars['JSON'];
  clusterProvisioningTaskList?: Maybe<ClusterProvisioningTaskList>;
  clusterProvisioningTaskStatusList: Array<Scalars['String']>;
  dnsRecordGet?: Maybe<DnsRecord>;
  dnsRecordSearch?: Maybe<Array<DnsRecord>>;
  certificateGenerateSelfSigned?: Maybe<CertificatePEMContainer>;
  certificateGenerate?: Maybe<CertificatePEMContainer>;
  tinkTest?: Maybe<Scalars['JSONObject']>;
  transactionList?: Maybe<TransactionListResult>;
  transactionGet?: Maybe<Transaction>;
  tagList?: Maybe<Array<Tag>>;
  tagGet?: Maybe<Tag>;
  tagListChildren?: Maybe<Array<Tag>>;
  tagListRoot?: Maybe<Array<Tag>>;
  deviceGetByHostingConfigIdAndToken?: Maybe<Device>;
  deviceListByHostingConfigIdAndToken?: Maybe<Array<Device>>;
  prometheusFetchInstanceUp?: Maybe<Scalars['Boolean']>;
  prometheusFetchInstanceIcmpAlive?: Maybe<Scalars['Boolean']>;
  phoneDirectoryEntryListByPhoneConfigIdAndToken?: Maybe<PhoneDirectoryEntryList>;
  videoConfigGetByIdAndToken?: Maybe<VideoConfig>;
  videoConfigList?: Maybe<Array<VideoConfig>>;
  videoConfigGet?: Maybe<VideoConfig>;
  cameraListByVideoConfigId?: Maybe<Array<Camera>>;
  cameraListByVideoConfigIdAndToken?: Maybe<Array<Camera>>;
  cameraGet?: Maybe<Camera>;
  runnerGet?: Maybe<Runner>;
  runnerSegmentList?: Maybe<Array<Scalars['String']>>;
};


export type QuerysearchArgs = {
  text?: InputMaybe<Scalars['String']>;
};


export type QueryuserGetArgs = {
  id: Scalars['String'];
};


export type QuerypictureListArgs = {
  cursor: CursorInput;
  input?: InputMaybe<PictureListInput>;
};


export type QuerypictureListByGuestIdArgs = {
  guestId: Scalars['String'];
};


export type QueryguestGetArgs = {
  id: Scalars['String'];
};


export type QueryguestSearchArgs = {
  name: Scalars['String'];
};


export type QuerycustomerListArgs = {
  input: CustomerListInput;
  cursor: CursorInput;
};


export type QuerycustomerGetArgs = {
  id: Scalars['String'];
};


export type QueryorderListArgs = {
  cursor: CursorInput;
  input: OrderListInput;
};


export type QueryorderGetArgs = {
  id: Scalars['String'];
};


export type QueryinvoiceListArgs = {
  input: InvoiceListInput;
  cursor: CursorInput;
};


export type QueryinvoiceGetArgs = {
  id: Scalars['String'];
};


export type QueryphoneCallListByPhoneConfigIdAndTokenArgs = {
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
  cursor: CursorInput;
};


export type QueryphoneCallCountMissedArgs = {
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
};


export type QueryphoneCallGetByPhoneConfigIdAndTokenArgs = {
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryphoneSequenceGetArgs = {
  id: Scalars['String'];
};


export type QueryphoneConfigGetByIdAndTokenArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type QueryphoneConfigGetArgs = {
  id: Scalars['String'];
};


export type QueryphoneSipPeerStatusArgs = {
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryphoneHardGetArgs = {
  id: Scalars['String'];
};


export type QueryphoneHardStatusArgs = {
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type QueryphoneHardSoftKeyConfigGetByTypeArgs = {
  type: PhoneHardTypeEnum;
};


export type QuerymetricsGetRecurentCAArgs = {
  year: Scalars['Float'];
};


export type QuerymetricsGetCAArgs = {
  year: Scalars['Float'];
};


export type QueryitemSearchArgs = {
  item: Scalars['String'];
};


export type QueryitemGetArgs = {
  id: Scalars['ID'];
};


export type QuerycontactGetArgs = {
  id: Scalars['String'];
};


export type QuerybankAccountListByCustomerIdArgs = {
  customerId: Scalars['String'];
};


export type QueryserverGetByIdAndTokenArgs = {
  id: Scalars['String'];
  serverToken: Scalars['String'];
};


export type QueryserverGetArgs = {
  id: Scalars['String'];
};


export type QueryhostingConfigGetArgs = {
  id: Scalars['String'];
};


export type QueryfirewallGetByServerIdArgs = {
  id: Scalars['String'];
};


export type QueryphoneQueueGetArgs = {
  id: Scalars['String'];
};


export type QueryclusterGetArgs = {
  id: Scalars['String'];
};


export type QueryclusterRoleGetArgs = {
  id: Scalars['String'];
};


export type QueryclusterProvisioningTaskListArgs = {
  cursor: CursorInput;
  input: ClusterProvisioningTaskListInput;
};


export type QuerydnsRecordGetArgs = {
  id: Scalars['String'];
};


export type QuerydnsRecordSearchArgs = {
  path: Scalars['String'];
};


export type QuerycertificateGenerateSelfSignedArgs = {
  name: Scalars['String'];
};


export type QuerycertificateGenerateArgs = {
  caContainer: CertificatePEMInput;
  name: Scalars['String'];
};


export type QuerytransactionListArgs = {
  cursor: CursorInput;
  input: TransactionListInput;
};


export type QuerytransactionGetArgs = {
  id: Scalars['String'];
};


export type QuerytagGetArgs = {
  id: Scalars['String'];
};


export type QuerytagListChildrenArgs = {
  id: Scalars['String'];
};


export type QuerydeviceGetByHostingConfigIdAndTokenArgs = {
  token: Scalars['String'];
  hostingConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type QuerydeviceListByHostingConfigIdAndTokenArgs = {
  token: Scalars['String'];
  id: Scalars['String'];
};


export type QueryprometheusFetchInstanceUpArgs = {
  job: Scalars['String'];
  instance: Scalars['String'];
};


export type QueryprometheusFetchInstanceIcmpAliveArgs = {
  job: Scalars['String'];
  instance: Scalars['String'];
};


export type QueryphoneDirectoryEntryListByPhoneConfigIdAndTokenArgs = {
  cursor: CursorInput;
  input: PhoneDirectoryEntryListInput;
  phoneConfigId: Scalars['String'];
  token: Scalars['String'];
};


export type QueryvideoConfigGetByIdAndTokenArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type QueryvideoConfigGetArgs = {
  id: Scalars['String'];
};


export type QuerycameraListByVideoConfigIdArgs = {
  id: Scalars['String'];
};


export type QuerycameraListByVideoConfigIdAndTokenArgs = {
  token: Scalars['String'];
  videoConfigId: Scalars['String'];
};


export type QuerycameraGetArgs = {
  id: Scalars['String'];
};


export type QueryrunnerGetArgs = {
  id: Scalars['String'];
};


export type QueryrunnerSegmentListArgs = {
  depth?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  id: Scalars['String'];
};

export type SearchResult = Invoice | Quote | Contact | Customer | Order | Item;

export type Invoice = {
  __typename?: 'Invoice';
  id: Scalars['ID'];
  publicId: Scalars['String'];
  date: Scalars['Date'];
  status?: Maybe<InvoiceStatusEnum>;
  creationDate: Scalars['Date'];
  updateDate: Scalars['Date'];
  companyId?: Maybe<Scalars['String']>;
  contractId?: Maybe<Scalars['String']>;
  contactId?: Maybe<Scalars['String']>;
  totalHT: Scalars['Float'];
  totalTTC: Scalars['Float'];
  orderId?: Maybe<Scalars['String']>;
  Contract?: Maybe<Contract>;
  Contact?: Maybe<Contact>;
  InvoiceLine?: Maybe<Array<InvoiceLine>>;
  Payment?: Maybe<Array<Payment>>;
};

export enum InvoiceStatusEnum {
  STATUS_PENDING = 'STATUS_PENDING',
  STATUS_DRAFT = 'STATUS_DRAFT',
  STATUS_PAID = 'STATUS_PAID',
  STATUS_ERROR = 'STATUS_ERROR',
  STATUS_CANCELLED = 'STATUS_CANCELLED',
  STATUS_PAYMENT_WAITING = 'STATUS_PAYMENT_WAITING'
}

export type Contract = {
  __typename?: 'Contract';
  id: Scalars['String'];
  countInvoice: Scalars['Float'];
  countQuote: Scalars['Float'];
  Customer: Customer;
  companyId: Scalars['String'];
  stripeConfigId?: Maybe<Scalars['String']>;
  Company?: Maybe<Company>;
  StripeConfig?: Maybe<StripeConfig>;
};

export type Customer = {
  __typename?: 'Customer';
  id: Scalars['ID'];
  name: Scalars['String'];
  initials: Scalars['String'];
  address_id?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  Address?: Maybe<Address>;
  Contact: Array<Contact>;
  PaymentPref?: Maybe<Array<PaymentPref>>;
  Company?: Maybe<Company>;
  Contract: Contract;
  CustomerToken: Array<CustomerToken>;
  Order: Array<Order>;
  Invoice: Array<Invoice>;
};

export type Address = {
  __typename?: 'Address';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  town?: Maybe<Scalars['String']>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  title: ContactTitleEnum;
  firstname?: Maybe<Scalars['String']>;
  lastname?: Maybe<Scalars['String']>;
  type?: Maybe<ContactTypeEnum>;
  email?: Maybe<Scalars['String']>;
  Customer: Customer;
};

export enum ContactTitleEnum {
  TITLE_MR = 'mr',
  TITLE_MS = 'ms'
}

export enum ContactTypeEnum {
  TYPE_FACTURATION = 'TYPE_FACTURATION',
  TYPE_ADMIN = 'TYPE_ADMIN',
  TYPE_USER = 'TYPE_USER'
}

export type PaymentPref = {
  __typename?: 'PaymentPref';
  id: Scalars['ID'];
  customer_id?: Maybe<Scalars['String']>;
  creationDate: Scalars['Date'];
  updateDate: Scalars['Date'];
  type: PaymentPrefTypeEnum;
  Card?: Maybe<Card>;
  BankAccount?: Maybe<BankAccount>;
};

export enum PaymentPrefTypeEnum {
  TYPE_CARD = 'TYPE_CARD',
  TYPE_DEBIT = 'TYPE_DEBIT',
  TYPE_TRANSFER = 'TYPE_TRANSFER'
}

export type Card = {
  __typename?: 'Card';
  id: Scalars['ID'];
  creationDate: Scalars['Date'];
  updateDate: Scalars['Date'];
  expMonth: Scalars['Float'];
  expYear: Scalars['Float'];
  last4: Scalars['String'];
  stripeId: Scalars['String'];
  stripeIsPaymentIntent: Scalars['Boolean'];
  Contract: Contract;
};

export type BankAccount = {
  __typename?: 'BankAccount';
  id: Scalars['ID'];
  creationDate: Scalars['Date'];
  updateDate: Scalars['Date'];
  bankName?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  stripeMandateUrl?: Maybe<Scalars['String']>;
  stripeId?: Maybe<Scalars['String']>;
  Contract?: Maybe<Contract>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  customerId?: Maybe<Customer>;
  Address?: Maybe<Address>;
  emailSender?: Maybe<Scalars['String']>;
  stripePk?: Maybe<Scalars['String']>;
  siret?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  tvaIntra?: Maybe<Scalars['String']>;
  Rib?: Maybe<Rib>;
  countInvoice?: Maybe<Scalars['Float']>;
  TinkUser?: Maybe<TinkUser>;
};

export type Rib = {
  __typename?: 'Rib';
  iban: Scalars['String'];
  bic: Scalars['String'];
};

export type TinkUser = {
  __typename?: 'TinkUser';
  id: Scalars['ID'];
  userId?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  credentialsId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  enrollStatus?: Maybe<TinkUserEnrollStatusEnum>;
};

export enum TinkUserEnrollStatusEnum {
  USER_NOT_ENROLLED = 'USER_NOT_ENROLLED',
  USER_ENROLLED = 'USER_ENROLLED'
}

export type CustomerToken = {
  __typename?: 'CustomerToken';
  id: Scalars['String'];
  Customer: Customer;
};

export type Order = {
  __typename?: 'Order';
  id: Scalars['ID'];
  companyId?: Maybe<Scalars['String']>;
  publicId: Scalars['String'];
  dateStart: Scalars['Date'];
  dateEnd?: Maybe<Scalars['Date']>;
  status: OrderStatusEnum;
  interval: OrderIntervalEnum;
  billingDayOfMonth: Scalars['Float'];
  contractId?: Maybe<Scalars['String']>;
  paymentPrefId?: Maybe<Scalars['String']>;
  OrderLine?: Maybe<Array<OrderLine>>;
  Contract?: Maybe<Contract>;
  PaymentPref?: Maybe<PaymentPref>;
};

export enum OrderStatusEnum {
  STATUS_DELETED = 'STATUS_DELETED',
  STATUS_DRAFT = 'STATUS_DRAFT',
  STATUS_WAITING_VALIDATION = 'STATUS_WAITING_VALIDATION',
  STATUS_ACTIVE = 'STATUS_ACTIVE',
  STATUS_DONE = 'STATUS_DONE'
}

export enum OrderIntervalEnum {
  INTERVAL_MONTH = 'INTERVAL_MONTH',
  INTERVAL_YEAR = 'INTERVAL_YEAR'
}

export type OrderLine = {
  __typename?: 'OrderLine';
  id: Scalars['ID'];
  creationDate: Scalars['Date'];
  updateDate: Scalars['Date'];
  quantity: Scalars['Float'];
  description?: Maybe<Scalars['String']>;
  dateStart: Scalars['Date'];
  dateEnd?: Maybe<Scalars['Date']>;
  itemId?: Maybe<Scalars['String']>;
  orderId: Order;
  Item?: Maybe<Item>;
  Order?: Maybe<Order>;
};

export type Item = {
  __typename?: 'Item';
  id: Scalars['ID'];
  creationDate: Scalars['Date'];
  updateDate: Scalars['Date'];
  label: Scalars['String'];
  price: Scalars['Float'];
  vat: Scalars['Float'];
  priceBuy?: Maybe<Scalars['Float']>;
  marginRaw: Scalars['Float'];
  OrderLine?: Maybe<Array<OrderLine>>;
};

export type StripeConfig = {
  __typename?: 'StripeConfig';
  id: Scalars['ID'];
  Contract: Contract;
};

export type InvoiceLine = {
  __typename?: 'InvoiceLine';
  id: Scalars['String'];
  creationDate: Scalars['Date'];
  updateDate: Scalars['Date'];
  quantity: Scalars['Float'];
  vat: Scalars['Float'];
  priceUnit: Scalars['Float'];
  label: Scalars['String'];
  dateStart: Scalars['Date'];
  dateEnd?: Maybe<Scalars['Date']>;
  priceBuy?: Maybe<Scalars['Float']>;
  marginRaw: Scalars['Float'];
  invoiceId: Scalars['String'];
  itemId?: Maybe<Scalars['String']>;
  Item?: Maybe<Item>;
};

export type Payment = {
  __typename?: 'Payment';
  id: Scalars['String'];
  creationDate: Scalars['Date'];
  updateDate?: Maybe<Scalars['Date']>;
  date: Scalars['Date'];
  type?: Maybe<PaymentTypeEnum>;
  comment?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  stripeId: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
  stripeClientSecret?: Maybe<Scalars['String']>;
  Invoice: Invoice;
};

export enum PaymentTypeEnum {
  TYPE_DEBIT = 'TYPE_DEBIT',
  card = 'card',
  TYPE_CARD = 'TYPE_CARD',
  check = 'check',
  transfer = 'transfer',
  debit = 'debit'
}

export type Quote = {
  __typename?: 'Quote';
  id: Scalars['ID'];
  publicId: Scalars['String'];
  date: Scalars['Date'];
  Contract: Scalars['String'];
  QuoteLine: Array<QuoteLine>;
};

export type QuoteLine = {
  __typename?: 'QuoteLine';
  id: Scalars['ID'];
  quantity: Scalars['Float'];
  priceUnit: Scalars['Float'];
  vat: Scalars['Float'];
  label: Scalars['String'];
  Quote: Quote;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email: Scalars['String'];
  roles: Array<UserRoleEnum>;
  impersonatedUser?: Maybe<Scalars['String']>;
  impersonator?: Maybe<Scalars['String']>;
  companyId?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  hostingConfigId?: Maybe<Scalars['String']>;
  Contact?: Maybe<Scalars['String']>;
  hostingConfigIds?: Maybe<Array<Scalars['String']>>;
  phoneConfigIds?: Maybe<Array<Scalars['String']>>;
  isManager: Scalars['Boolean'];
  isUser: Scalars['Boolean'];
  isAdmin: Scalars['Boolean'];
  isWebmaster: Scalars['Boolean'];
  isBilling: Scalars['Boolean'];
  Company?: Maybe<Company>;
};

export enum UserRoleEnum {
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_MANAGER = 'ROLE_MANAGER',
  ROLE_USER = 'ROLE_USER',
  ROLE_BILLING = 'ROLE_BILLING',
  ROLE_WEBMASTER = 'ROLE_WEBMASTER',
  ROLE_HOSTING = 'ROLE_HOSTING',
  ROLE_PHONE = 'ROLE_PHONE',
  ROLE_TECHNICAL = 'ROLE_TECHNICAL'
}

export type PictureListResult = {
  __typename?: 'PictureListResult';
  cursor?: Maybe<Cursor>;
  nodes?: Maybe<Array<Picture>>;
  input?: Maybe<Scalars['JSONObject']>;
};

export type Cursor = {
  __typename?: 'Cursor';
  hasMore: Scalars['Boolean'];
  totalPages: Scalars['Int'];
  page: Scalars['Int'];
  limit: Scalars['Int'];
};

export type Picture = {
  __typename?: 'Picture';
  id: Scalars['ID'];
  guestId?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['Date']>;
  fileId?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  isLandscape?: Maybe<Scalars['Boolean']>;
  orientation?: Maybe<Scalars['String']>;
  thumbnailFileId?: Maybe<Scalars['String']>;
  category?: Maybe<PictureCategoryEnum>;
  File?: Maybe<File>;
  Guest?: Maybe<Guest>;
  ThumbnailFile?: Maybe<File>;
};

export enum PictureCategoryEnum {
  LOVER_PHOTO = 'LOVER_PHOTO',
  GROOM_WITH_PARENTS = 'GROOM_WITH_PARENTS',
  LAUGHTER = 'LAUGHTER',
  WEDS_SELFY = 'WEDS_SELFY',
  INSOLITE = 'INSOLITE',
  JUMP = 'JUMP',
  ANIMATED = 'ANIMATED',
  WEDS_CUTE = 'WEDS_CUTE',
  SHORT_DRESS = 'SHORT_DRESS',
  NOEUD_PAPILLON = 'NOEUD_PAPILLON',
  BRIDE_BOUQUET = 'BRIDE_BOUQUET',
  LANDSCAPE = 'LANDSCAPE',
  BRIDE_PARENTS = 'BRIDE_PARENTS',
  BRIDESMAIDS = 'BRIDESMAIDS',
  HEXAGONAL = 'HEXAGONAL',
  EMOUVANT = 'EMOUVANT',
  YOUR_TABLE = 'YOUR_TABLE',
  MEAL_EXPRESSION = 'MEAL_EXPRESSION',
  SOMETHING_BLUE = 'SOMETHING_BLUE',
  SOMETHING_EATABLE = 'SOMETHING_EATABLE',
  GOLDEN_BOOK = 'GOLDEN_BOOK',
  CHEESE = 'CHEESE',
  SOMEBODY_DANCING = 'SOMEBODY_DANCING',
  SOMETHING_FLYING = 'SOMETHING_FLYING',
  WEDS = 'WEDS',
  SHINY = 'SHINY',
  RIGHT_NEIGHBOR = 'RIGHT_NEIGHBOR',
  FUNNY_SELFY = 'FUNNY_SELFY',
  CRAVATE = 'CRAVATE',
  SURPRENEZ_VOUS = 'SURPRENEZ_VOUS'
}

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  bucket?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type Guest = {
  __typename?: 'Guest';
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type CursorInput = {
  page: Scalars['Int'];
  limit: Scalars['Int'];
};

export type PictureListInput = {
  category?: InputMaybe<Scalars['String']>;
  guestId?: InputMaybe<Scalars['String']>;
};

export type CustomerCursorResult = {
  __typename?: 'CustomerCursorResult';
  cursor: Cursor;
  nodes: Array<Customer>;
};

export type CustomerListInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type OrderCursorList = {
  __typename?: 'OrderCursorList';
  nodes: Array<Order>;
  cursor: Cursor;
};

export type OrderListInput = {
  status?: InputMaybe<Array<OrderStatusEnum>>;
  contractId?: InputMaybe<Array<Scalars['String']>>;
  orderField?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
};

export type InvoiceCursorList = {
  __typename?: 'InvoiceCursorList';
  nodes: Array<Invoice>;
  cursor: Cursor;
};

export type InvoiceListInput = {
  status: Array<InvoiceStatusEnum>;
  dateStart?: InputMaybe<Scalars['Date']>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  contract?: InputMaybe<Array<Scalars['String']>>;
  orderField?: InputMaybe<InvoiceOrderEnum>;
  orderDirection?: InputMaybe<InvoiceOrderDirectionEnum>;
};

export enum InvoiceOrderEnum {
  DATE = 'DATE',
  STATUS = 'STATUS',
  PUBLICID = 'PUBLICID'
}

export enum InvoiceOrderDirectionEnum {
  DESC = 'DESC',
  ASC = 'ASC'
}

export type PhoneCallListResult = {
  __typename?: 'PhoneCallListResult';
  nodes: Array<PhoneCall>;
  cursor?: Maybe<Cursor>;
};

export type PhoneCall = {
  __typename?: 'PhoneCall';
  id?: Maybe<Scalars['String']>;
  channelId?: Maybe<Scalars['String']>;
  configId?: Maybe<Scalars['String']>;
  status?: Maybe<PhoneCallStatusEnum>;
  actionStatus?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  queueStartTime?: Maybe<Scalars['Date']>;
  incomingContext?: Maybe<Scalars['String']>;
  incomingExtension?: Maybe<Scalars['String']>;
  incomingTime?: Maybe<Scalars['Date']>;
  hangupTime?: Maybe<Scalars['Date']>;
  answeredBy?: Maybe<Scalars['String']>;
  answeredTime?: Maybe<Scalars['Date']>;
  variables?: Maybe<Scalars['JSONObject']>;
};

export enum PhoneCallStatusEnum {
  INCOMING = 'INCOMING',
  QUEUED = 'QUEUED',
  HANGUP = 'HANGUP',
  ANSWERED = 'ANSWERED'
}

export type PhoneSequence = {
  __typename?: 'PhoneSequence';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  itemList?: Maybe<Array<PhoneSequenceItem>>;
};

export type PhoneSequenceItem = {
  __typename?: 'PhoneSequenceItem';
  type?: Maybe<PhoneSequenceItemType>;
  timeout: Scalars['Float'];
  position: Scalars['Float'];
  data: PhoneSequenceItemData;
};

export enum PhoneSequenceItemType {
  TYPE_QUEUE = 'TYPE_QUEUE',
  TYPE_REDIRECT = 'TYPE_REDIRECT',
  TYPE_CTI = 'TYPE_CTI',
  TYPE_SWITCH = 'TYPE_SWITCH',
  TYPE_MUSICONHOLD = 'TYPE_MUSICONHOLD',
  TYPE_VOICEMAIL = 'TYPE_VOICEMAIL',
  TYPE_PLAYBACK = 'TYPE_PLAYBACK'
}

export type PhoneSequenceItemData = {
  __typename?: 'PhoneSequenceItemData';
  phoneSequence?: Maybe<Scalars['JSONObject']>;
  name?: Maybe<Scalars['String']>;
  exitOnEnd?: Maybe<Scalars['Boolean']>;
  queueId?: Maybe<Scalars['String']>;
  switchId?: Maybe<Scalars['String']>;
  voicemailId?: Maybe<Scalars['String']>;
  redirectPeerId?: Maybe<Scalars['String']>;
  redirect?: Maybe<PhoneRedirect>;
  announcement?: Maybe<PhoneAnnouncement>;
  ivrTreeId?: Maybe<Scalars['String']>;
  musicOnHoldName?: Maybe<Scalars['String']>;
};

export type PhoneRedirect = {
  __typename?: 'PhoneRedirect';
  destType?: Maybe<PhoneRedirectDestTypeEnum>;
  type?: Maybe<PhoneRedirectTypeEnum>;
  redirectPeerId?: Maybe<Scalars['String']>;
  redirectPeerName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  extensionId?: Maybe<Scalars['String']>;
  Peer?: Maybe<PhoneSipPeer>;
  Extension?: Maybe<PhoneExtension>;
};

export enum PhoneRedirectDestTypeEnum {
  REDIRECT_DEST_PEER = 'REDIRECT_DEST_PEER',
  REDIRECT_DEST_NUMBER = 'REDIRECT_DEST_NUMBER',
  REDIRECT_DEST_EXTENSION = 'REDIRECT_DEST_EXTENSION'
}

export enum PhoneRedirectTypeEnum {
  REDIRECT_DIRECT = 'REDIRECT_DIRECT',
  REDIRECT_QUEUE = 'REDIRECT_QUEUE'
}

export type PhoneSipPeer = {
  __typename?: 'PhoneSipPeer';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  serverId?: Maybe<Scalars['String']>;
  enableTLS?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PhoneSipPeerTypeEnum>;
  displayInDirectory?: Maybe<Scalars['Boolean']>;
  host?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
  legacyDialplan?: Maybe<Scalars['Boolean']>;
  phoneSipLineId?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  outgoingPhoneNumberId?: Maybe<Scalars['String']>;
  answeringSoundId?: Maybe<Scalars['String']>;
  queuePeriodicSoundId?: Maybe<Scalars['String']>;
  voicemailMain?: Maybe<Scalars['String']>;
  voicemailScenarioId?: Maybe<Scalars['String']>;
  voicemailPrimaryId?: Maybe<Scalars['String']>;
  voicemailSecondaryId?: Maybe<Scalars['String']>;
  PhoneConfig?: Maybe<PhoneSipLine>;
  PhoneSipLine?: Maybe<PhoneSipLine>;
  OutgoingPhoneNumber?: Maybe<PhoneNumber>;
  AnsweringSound?: Maybe<PhoneSound>;
  QueuePeriodicSound?: Maybe<PhoneSound>;
  ScenarioVoicemail?: Maybe<PhoneScenario>;
  PhoneHard?: Maybe<PhoneHard>;
  Extension?: Maybe<PhoneExtension>;
};

export enum PhoneSipPeerTypeEnum {
  TYPE_CLASSIC = 'TYPE_CLASSIC',
  TYPE_SECURE = 'TYPE_SECURE',
  TYPE_WEBSOCKET = 'TYPE_WEBSOCKET'
}

export type PhoneSipLine = {
  __typename?: 'PhoneSipLine';
  id: Scalars['ID'];
  name: Scalars['String'];
  host: Scalars['String'];
  authuser?: Maybe<Scalars['String']>;
  user: Scalars['String'];
  secret: Scalars['String'];
  outboundProxy?: Maybe<Scalars['String']>;
  type?: Maybe<PhoneSipLineTypeEnum>;
  registrationActive: Scalars['Boolean'];
  priority?: Maybe<Scalars['Float']>;
  outNumberId?: Maybe<Scalars['String']>;
  phoneConfigId: Scalars['String'];
  PhoneNumber: Array<PhoneNumber>;
  OutNumber?: Maybe<PhoneNumber>;
};

export enum PhoneSipLineTypeEnum {
  TYPE_OVH = 'TYPE_OVH',
  TYPE_TWILIO = 'TYPE_TWILIO',
  TYPE_3CX = 'TYPE_3CX',
  TYPE_KEYYO = 'TYPE_KEYYO',
  TYPE_UNYC = 'TYPE_UNYC'
}

export type PhoneNumber = {
  __typename?: 'PhoneNumber';
  id: Scalars['ID'];
  number: Scalars['String'];
  phoneSipLineId?: Maybe<Scalars['String']>;
  phoneConfigId: Scalars['String'];
  type?: Maybe<PhoneNumberRedirectType>;
  phoneScenarioId?: Maybe<Scalars['String']>;
  phoneSipPeerId?: Maybe<Scalars['String']>;
  phoneExtensionId?: Maybe<Scalars['String']>;
  sequenceId?: Maybe<Scalars['String']>;
  PhoneScenario?: Maybe<PhoneScenario>;
  PhoneSipLine?: Maybe<PhoneScenario>;
  PhoneSipPeer?: Maybe<PhoneSipPeer>;
  PhoneExtension?: Maybe<PhoneExtension>;
};

export enum PhoneNumberRedirectType {
  REDIRECT_SCENARIO = 'REDIRECT_SCENARIO',
  REDIRECT_PEER = 'REDIRECT_PEER',
  REDIRECT_EXTENSION = 'REDIRECT_EXTENSION',
  REDIRECT_SEQUENCE = 'REDIRECT_SEQUENCE'
}

export type PhoneScenario = {
  __typename?: 'PhoneScenario';
  id: Scalars['ID'];
  name: Scalars['String'];
  phoneConfigId: Scalars['String'];
  type?: Maybe<PhoneScenarioTypeEnum>;
  PhoneRedirect?: Maybe<PhoneRedirect>;
  PhoneAnswering?: Maybe<PhoneAnswering>;
  IvrTree?: Maybe<Array<IvrTree>>;
  PhoneQueue?: Maybe<PhoneQueue>;
  PhoneNumber?: Maybe<Array<PhoneNumber>>;
  conditionalScenarioId?: Maybe<Scalars['String']>;
  PhoneConfig?: Maybe<PhoneConfig>;
  ConditionalScenario?: Maybe<PhoneScenario>;
};

export enum PhoneScenarioTypeEnum {
  IVR = 'IVR',
  REDIRECT = 'REDIRECT',
  ANSWERING = 'ANSWERING',
  QUEUE = 'QUEUE',
  SEQUENCE = 'SEQUENCE'
}

export type PhoneAnswering = {
  __typename?: 'PhoneAnswering';
  type?: Maybe<PhoneAnsweringTypeEnum>;
  announcement?: Maybe<PhoneAnnouncement>;
};

export enum PhoneAnsweringTypeEnum {
  SIMPLE = 'SIMPLE',
  MESSAGE = 'MESSAGE'
}

export type PhoneAnnouncement = {
  __typename?: 'PhoneAnnouncement';
  type?: Maybe<PhoneAnnouncementType>;
  ttsText?: Maybe<Scalars['String']>;
  soundId?: Maybe<Scalars['String']>;
};

export enum PhoneAnnouncementType {
  TYPE_TTS = 'TYPE_TTS',
  TYPE_SOUND = 'TYPE_SOUND'
}

export type IvrTree = {
  __typename?: 'IvrTree';
  id?: Maybe<Scalars['ID']>;
  nodeId?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  expanded?: Maybe<Scalars['Boolean']>;
  type?: Maybe<IvrTreeTypeEnum>;
  options?: Maybe<IvrTreeOption>;
  children?: Maybe<Array<IvrTree>>;
};

export enum IvrTreeTypeEnum {
  TYPE_REDIRECT = 'TYPE_REDIRECT',
  TYPE_CONFBRIDGE = 'TYPE_CONFBRIDGE',
  TYPE_SUBMENU = 'TYPE_SUBMENU'
}

export type IvrTreeOption = {
  __typename?: 'IvrTreeOption';
  type?: Maybe<IvrTreeOptionType>;
  announcement?: Maybe<PhoneAnnouncement>;
  number?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
  scenarioId?: Maybe<Scalars['String']>;
  sequenceId?: Maybe<Scalars['String']>;
  extensionId?: Maybe<Scalars['String']>;
};

export enum IvrTreeOptionType {
  TYPE_REDIRECT_SCENARIO = 'TYPE_REDIRECT_SCENARIO',
  TYPE_REDIRECT_EXTERNAL = 'TYPE_REDIRECT_EXTERNAL',
  TYPE_REDIRECT_EXTENSION = 'TYPE_REDIRECT_EXTENSION',
  TYPE_REDIRECT_PEER = 'TYPE_REDIRECT_PEER',
  TYPE_REDIRECT_SEQUENCE = 'TYPE_REDIRECT_SEQUENCE'
}

export type PhoneQueue = {
  __typename?: 'PhoneQueue';
  id?: Maybe<Scalars['ID']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  members?: Maybe<Array<PhoneQueueMember>>;
  strategy?: Maybe<PhoneQueueStrategyEnum>;
  musicOnHoldId?: Maybe<Scalars['String']>;
  agentRingTimeout?: Maybe<Scalars['Float']>;
  waitingStrategy?: Maybe<PhoneQueueWaitingStrategy>;
  incomingSound?: Maybe<PhoneAnnouncement>;
  periodicSound?: Maybe<PhoneQueuePeriodicSound>;
  MusicOnHold?: Maybe<MusicOnHold>;
};

export type PhoneQueueMember = {
  __typename?: 'PhoneQueueMember';
  type?: Maybe<PhoneQueueMemberTypeEnum>;
  timeout?: Maybe<Scalars['Float']>;
  number?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
  active?: Maybe<Scalars['Boolean']>;
  position?: Maybe<Scalars['Float']>;
  Peer?: Maybe<PhoneSipPeer>;
};

export enum PhoneQueueMemberTypeEnum {
  TYPE_PEER = 'TYPE_PEER',
  TYPE_EXTERNAL = 'TYPE_EXTERNAL'
}

export enum PhoneQueueStrategyEnum {
  STRATEGY_ROUND_ROBIN = 'STRATEGY_ROUND_ROBIN',
  STRATEGY_RING_ALL = 'STRATEGY_RING_ALL',
  PHONE_QUEUE_STRATEGY_ROUND_ROBIN = 'PHONE_QUEUE_STRATEGY_ROUND_ROBIN',
  PHONE_QUEUE_STRATEGY_RING_ALL = 'PHONE_QUEUE_STRATEGY_RING_ALL'
}

export type PhoneQueueWaitingStrategy = {
  __typename?: 'PhoneQueueWaitingStrategy';
  type?: Maybe<PhoneQueueWaitingStrategyEnum>;
  timeout?: Maybe<Scalars['Float']>;
  endAnnouncement?: Maybe<PhoneAnnouncement>;
};

export enum PhoneQueueWaitingStrategyEnum {
  PHONE_QUEUE_WAITING_STRATEGY_UNLIMITED = 'PHONE_QUEUE_WAITING_STRATEGY_UNLIMITED',
  PHONE_QUEUE_WAITING_STRATEGY_CUSTOM = 'PHONE_QUEUE_WAITING_STRATEGY_CUSTOM',
  PHONE_QUEUE_WAITING_STRATEGY_CUSTOM_ANSWERING = 'PHONE_QUEUE_WAITING_STRATEGY_CUSTOM_ANSWERING',
  PHONE_QUEUE_WAITING_STRATEGY_CUSTOM_CALLBACK = 'PHONE_QUEUE_WAITING_STRATEGY_CUSTOM_CALLBACK'
}

export type PhoneQueuePeriodicSound = {
  __typename?: 'PhoneQueuePeriodicSound';
  announcement?: Maybe<PhoneAnnouncement>;
  frequency?: Maybe<Scalars['Float']>;
};

export type MusicOnHold = {
  __typename?: 'MusicOnHold';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  fileList?: Maybe<Scalars['String']>;
  phoneSoundIdList?: Maybe<Array<Scalars['String']>>;
  PhoneSound?: Maybe<Array<PhoneSound>>;
};

export type PhoneSound = {
  __typename?: 'PhoneSound';
  id: Scalars['ID'];
  name: Scalars['String'];
  format?: Maybe<PhoneSoundFormatEnum>;
  base64blob?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
};

export enum PhoneSoundFormatEnum {
  FORMAT_WAV = 'FORMAT_WAV',
  FORMAT_ALAW = 'FORMAT_ALAW',
  FORMAT_ULAW = 'FORMAT_ULAW'
}

export type PhoneConfig = {
  __typename?: 'PhoneConfig';
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  hostingConfigId?: Maybe<Scalars['String']>;
  dnsRecordId?: Maybe<Scalars['String']>;
  legacySip?: Maybe<Scalars['Boolean']>;
  AsteriskConfig: AsteriskConfig;
  PhoneSipLine: Array<PhoneSipLine>;
  PhoneHard: Array<PhoneHard>;
  PhoneSipPeer: Array<PhoneSipPeer>;
  PhoneScenario?: Maybe<Array<PhoneScenario>>;
  PhoneNumber: Array<PhoneNumber>;
  PhoneSoundList: Array<Scalars['String']>;
  PhoneSound: Array<PhoneSound>;
  Server?: Maybe<Server>;
  MusicOnHold?: Maybe<Array<MusicOnHold>>;
  Extension?: Maybe<Array<PhoneExtension>>;
  PhoneSequence?: Maybe<Array<PhoneSequence>>;
  Queue?: Maybe<Array<PhoneQueue>>;
  Voicemail?: Maybe<Array<Voicemail>>;
  IvrTree?: Maybe<Array<IvrTree>>;
  PhoneSwitch?: Maybe<Array<PhoneSwitch>>;
  SoftKeyConfigList?: Maybe<Array<SoftKeyConfig>>;
  PhoneHook?: Maybe<Array<PhoneHook>>;
  Directory?: Maybe<PhoneDirectory>;
};

export type AsteriskConfig = {
  __typename?: 'AsteriskConfig';
  id: Scalars['ID'];
  phoneConfigId?: Maybe<Scalars['String']>;
  prefixName?: Maybe<Scalars['String']>;
};

export type PhoneHard = {
  __typename?: 'PhoneHard';
  id: Scalars['ID'];
  mac?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  serial?: Maybe<Scalars['Float']>;
  forceFirmwareUpgrade?: Maybe<Scalars['Boolean']>;
  type?: Maybe<PhoneHardTypeEnum>;
  enableTLS?: Maybe<Scalars['Boolean']>;
  phoneSipPeerId?: Maybe<Scalars['String']>;
  phoneLineIdList?: Maybe<Array<Scalars['String']>>;
  options?: Maybe<PhoneHardOptions>;
  PhoneSipPeer?: Maybe<PhoneSipPeer>;
  PhoneLineList?: Maybe<Array<PhoneSipPeer>>;
  SoftKeyConfig?: Maybe<SoftKeyConfig>;
};

export enum PhoneHardTypeEnum {
  SPA232 = 'SPA232',
  SPA3XX = 'SPA3XX',
  CP78XX = 'CP78XX',
  CP8841 = 'CP8841',
  XENIOS_ITL010 = 'XENIOS_ITL010',
  XENIOS_ICD012 = 'XENIOS_ICD012',
  YEALINK_T33G = 'YEALINK_T33G',
  YEALINK_T31G = 'YEALINK_T31G',
  YEALINK_T53 = 'YEALINK_T53',
  YEALINK_T53W = 'YEALINK_T53W',
  YEALINK_T53W_EXPANSION = 'YEALINK_T53W_EXPANSION',
  YEALINK_T54W = 'YEALINK_T54W',
  YEALINK_T58W = 'YEALINK_T58W',
  YEALINK_T58W_EXPANSION = 'YEALINK_T58W_EXPANSION',
  YEALINK_T54W_EXPANSION = 'YEALINK_T54W_EXPANSION',
  YEALINK_W73P = 'YEALINK_W73P'
}

export type PhoneHardOptions = {
  __typename?: 'PhoneHardOptions';
  softKeys?: Maybe<Scalars['JSONObject']>;
  softKeyList?: Maybe<Array<PhoneHardSoftKey>>;
  softKeyExpansionList?: Maybe<Array<PhoneHardSoftKey>>;
};

export type PhoneHardSoftKey = {
  __typename?: 'PhoneHardSoftKey';
  index?: Maybe<Scalars['Float']>;
  type?: Maybe<PhoneHardSoftKeyTypeEnum>;
  lineIndex?: Maybe<Scalars['String']>;
  peer?: Maybe<Scalars['String']>;
  extension?: Maybe<Scalars['String']>;
  scenario?: Maybe<Scalars['String']>;
  switchId?: Maybe<Scalars['String']>;
  directoryEntryId?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  PhoneSipPeer?: Maybe<PhoneSipPeer>;
  PhoneExtension?: Maybe<PhoneExtension>;
  PhoneScenario?: Maybe<PhoneScenario>;
  PhoneSwitch?: Maybe<PhoneSwitch>;
};

export enum PhoneHardSoftKeyTypeEnum {
  TYPE_LINE = 'TYPE_LINE',
  TYPE_EXTENSION = 'TYPE_EXTENSION',
  TYPE_BLF_SCENARIO = 'TYPE_BLF_SCENARIO',
  TYPE_BLF_SWITCH = 'TYPE_BLF_SWITCH',
  TYPE_DIRECTORY_ENTRY = 'TYPE_DIRECTORY_ENTRY',
  TYPE_SPEED_DIAL = 'TYPE_SPEED_DIAL',
  TYPE_SPEED_DIAL_BLF = 'TYPE_SPEED_DIAL_BLF',
  TYPE_SPEED_DIAL_BLF_PICKUP = 'TYPE_SPEED_DIAL_BLF_PICKUP'
}

export type PhoneExtension = {
  __typename?: 'PhoneExtension';
  id: Scalars['ID'];
  phoneConfigId: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ExtensionTypeEnum>;
  number?: Maybe<Scalars['String']>;
  scenarioId?: Maybe<Scalars['String']>;
  sequenceId?: Maybe<Scalars['String']>;
  hookId?: Maybe<Scalars['String']>;
  peerId?: Maybe<Scalars['String']>;
  switchId?: Maybe<Scalars['String']>;
  skipRinging?: Maybe<Scalars['Boolean']>;
  Scenario?: Maybe<PhoneScenario>;
  Peer?: Maybe<PhoneSipPeer>;
  Switch?: Maybe<PhoneSwitch>;
  Sequence?: Maybe<PhoneSequence>;
  Hook?: Maybe<PhoneHook>;
};

export enum ExtensionTypeEnum {
  TYPE_PEER = 'TYPE_PEER',
  TYPE_BUTTON = 'TYPE_BUTTON',
  TYPE_SEQUENCE = 'TYPE_SEQUENCE',
  TYPE_HOOK = 'TYPE_HOOK'
}

export type PhoneSwitch = {
  __typename?: 'PhoneSwitch';
  id: Scalars['ID'];
  phoneConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type PhoneHook = {
  __typename?: 'PhoneHook';
  id?: Maybe<Scalars['ID']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type SoftKeyConfig = {
  __typename?: 'SoftKeyConfig';
  phoneType?: Maybe<PhoneHardTypeEnum>;
  nbKey?: Maybe<Scalars['Float']>;
  hasLine?: Maybe<Scalars['Boolean']>;
  nbLine?: Maybe<Scalars['Float']>;
  hasSpeedDial?: Maybe<Scalars['Boolean']>;
  hasExtension?: Maybe<Scalars['Boolean']>;
  hasBlfScenario?: Maybe<Scalars['Boolean']>;
  hasBlfSwitch?: Maybe<Scalars['Boolean']>;
  hasBlf?: Maybe<Scalars['Boolean']>;
  hasPickup?: Maybe<Scalars['Boolean']>;
  nbKeyExpansion?: Maybe<Scalars['Float']>;
  hasExpansion?: Maybe<Scalars['Boolean']>;
};

export type Server = {
  __typename?: 'Server';
  id: Scalars['ID'];
  token: Scalars['String'];
  hostingConfigId: Scalars['String'];
  providerId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  type?: Maybe<ServerTypeEnum>;
  version?: Maybe<Scalars['String']>;
  lastseen?: Maybe<Scalars['Date']>;
  lastBackup?: Maybe<Scalars['Date']>;
  serial?: Maybe<Scalars['Date']>;
  status?: Maybe<ServerStatusEnum>;
  ip?: Maybe<Scalars['String']>;
  provStatus?: Maybe<ServerProvStatus>;
  backupTargetId?: Maybe<Scalars['String']>;
  Firewall?: Maybe<Firewall>;
  BackupConfig?: Maybe<BackupConfig>;
  ClusterConfig?: Maybe<ClusterConfig>;
  BackupTarget?: Maybe<BackupConfig>;
  PhoneConfig?: Maybe<PhoneConfig>;
};

export enum ServerTypeEnum {
  TYPE_API = 'TYPE_API',
  TYPE_WEB = 'TYPE_WEB',
  TYPE_PHONE = 'TYPE_PHONE',
  TYPE_CLUSTER = 'TYPE_CLUSTER',
  SCALEWAY_DEV1_S = 'DEV1-S',
  DEDIBOX = 'DEDIBOX',
  AMAZON_EC2_MICRO = 'AMAZON_EC2_MICRO'
}

export enum ServerStatusEnum {
  STATUS_NOK = 'STATUS_NOK',
  STATUS_OK = 'STATUS_OK'
}

export enum ServerProvStatus {
  INITIAL = 'INITIAL',
  OK = 'OK',
  UPDATE_NEEDED = 'UPDATE_NEEDED'
}

export type Firewall = {
  __typename?: 'Firewall';
  id: Scalars['ID'];
  Server: Server;
  Rules: Array<FirewallRule>;
};

export type FirewallRule = {
  __typename?: 'FirewallRule';
  id?: Maybe<Scalars['String']>;
  type?: Maybe<FirewallRuleType>;
  from: Scalars['String'];
  action?: Maybe<FirewallRuleAction>;
  port?: Maybe<Scalars['Float']>;
  portRangeStart?: Maybe<Scalars['Float']>;
  portRangeEnd?: Maybe<Scalars['Float']>;
  position: Scalars['Float'];
  desc?: Maybe<Scalars['String']>;
};

export enum FirewallRuleType {
  ALL = 'ALL',
  TCP = 'TCP',
  UDP = 'UDP'
}

export enum FirewallRuleAction {
  DROP = 'DROP',
  REJECT = 'REJECT',
  ACCEPT = 'ACCEPT'
}

export type BackupConfig = {
  __typename?: 'BackupConfig';
  id: Scalars['ID'];
  accessKey: Scalars['String'];
  name: Scalars['String'];
  secretKey: Scalars['String'];
  endpoint: Scalars['String'];
  options?: Maybe<BackupOptions>;
  Server?: Maybe<Server>;
};

export type BackupOptions = {
  __typename?: 'BackupOptions';
  frequency?: Maybe<BackupFrequencyEnum>;
  dailyRetention?: Maybe<Scalars['Int']>;
  weeklyRetention?: Maybe<Scalars['Int']>;
  monthlyRetention?: Maybe<Scalars['Int']>;
};

export enum BackupFrequencyEnum {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY'
}

export type ClusterConfig = {
  __typename?: 'ClusterConfig';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['Float']>;
  active?: Maybe<Scalars['Boolean']>;
  clusterId?: Maybe<Scalars['String']>;
  serverId?: Maybe<Scalars['String']>;
  dnsRecordId?: Maybe<Scalars['String']>;
  Cluster?: Maybe<Cluster>;
  ClusterRoleConfig?: Maybe<Array<ClusterRoleConfig>>;
  Server?: Maybe<Server>;
  DnsRecord?: Maybe<DnsRecord>;
};

export type Cluster = {
  __typename?: 'Cluster';
  id: Scalars['ID'];
  hostingConfigId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  creationDate?: Maybe<Scalars['Date']>;
  active?: Maybe<Scalars['Boolean']>;
  updateDate?: Maybe<Scalars['Date']>;
  options: Scalars['JSONObject'];
  dnsRecordId?: Maybe<Scalars['String']>;
  ClusterConfig?: Maybe<Array<ClusterConfig>>;
  user?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  HostingConfig?: Maybe<HostingConfig>;
  DnsRecord?: Maybe<DnsRecord>;
};

export type HostingConfig = {
  __typename?: 'HostingConfig';
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  Server?: Maybe<Array<Server>>;
};

export type DnsRecord = {
  __typename?: 'DnsRecord';
  id: Scalars['ID'];
  parentRecordId?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  type?: Maybe<DnsRecordTypeEnum>;
  value?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  fullPath?: Maybe<Scalars['String']>;
  cert?: Maybe<CertificatePEMContainer>;
  Root: DnsRecord;
  Parent: DnsRecord;
};

export enum DnsRecordTypeEnum {
  A = 'A',
  CNAME = 'CNAME'
}

export type CertificatePEMContainer = {
  __typename?: 'CertificatePEMContainer';
  fullchainPEM?: Maybe<Scalars['String']>;
  certPEM: Scalars['String'];
  privateKeyPEM: Scalars['String'];
  publicKeyPEM: Scalars['String'];
};

export type ClusterRoleConfig = {
  __typename?: 'ClusterRoleConfig';
  id?: Maybe<Scalars['ID']>;
  roleId?: Maybe<Scalars['String']>;
  clusterRoleId?: Maybe<Scalars['String']>;
  clusterConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<ClusterRoleTypeEnum>;
  dnsRecordId?: Maybe<Scalars['String']>;
  options?: Maybe<Scalars['JSONObject']>;
  status?: Maybe<ClusterRoleConfigStatusEnum>;
  version?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Float']>;
  serial?: Maybe<Scalars['Float']>;
  ClusterRole?: Maybe<ClusterRole>;
  DnsRecord?: Maybe<DnsRecord>;
};

export enum ClusterRoleTypeEnum {
  AGENT = 'AGENT',
  ASTERISK = 'ASTERISK',
  CACHE = 'CACHE',
  CAPTIVE = 'CAPTIVE',
  DATABASE = 'DATABASE',
  DBADMIN = 'DBADMIN',
  DEPLOYER = 'DEPLOYER',
  DNS = 'DNS',
  FTP = 'FTP',
  GRAFANA = 'GRAFANA',
  IPSEC = 'IPSEC',
  LOADBALANCER = 'LOADBALANCER',
  METABASE = 'METABASE',
  MQTT = 'MQTT',
  NODE = 'NODE',
  PHP = 'PHP',
  PROMETHEUS = 'PROMETHEUS',
  ALERT_MANAGER = 'ALERT_MANAGER',
  BLACKBOX_EXPORTER = 'BLACKBOX_EXPORTER',
  MQTT_EXPORTER = 'MQTT_EXPORTER',
  VSCODE = 'VSCODE',
  SNMP_EXPORTER = 'SNMP_EXPORTER',
  STATIC = 'STATIC',
  MINIO = 'MINIO',
  SYSLOG = 'SYSLOG',
  SHINOBI = 'SHINOBI',
  UNIFI = 'UNIFI',
  ZONEMINDER = 'ZONEMINDER'
}

export enum ClusterRoleConfigStatusEnum {
  INITIAL = 'INITIAL',
  PRESENT = 'PRESENT'
}

export type ClusterRole = {
  __typename?: 'ClusterRole';
  id: Scalars['ID'];
  name: Scalars['String'];
  image: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  isLoadBalancerClient?: Maybe<Scalars['Boolean']>;
  version?: Maybe<Scalars['Float']>;
  type: ClusterRoleTypeEnum;
  options?: Maybe<ClusterRoleOptions>;
  envVarList: Array<Scalars['JSONObject']>;
};

export type ClusterRoleOptions = {
  __typename?: 'ClusterRoleOptions';
  envVarList?: Maybe<Array<EnvVar>>;
};

export type EnvVar = {
  __typename?: 'EnvVar';
  name: Scalars['String'];
  value: Scalars['String'];
};

export type Voicemail = {
  __typename?: 'Voicemail';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneConfigId?: Maybe<Scalars['String']>;
  type?: Maybe<VoicemailTypeEnum>;
  email?: Maybe<Scalars['String']>;
  announcement?: Maybe<PhoneAnnouncement>;
};

export enum VoicemailTypeEnum {
  SIMPLE = 'SIMPLE',
  MESSAGE = 'MESSAGE'
}

export type PhoneDirectory = {
  __typename?: 'PhoneDirectory';
  id: Scalars['ID'];
  phoneConfigId?: Maybe<Scalars['String']>;
  Entry?: Maybe<Array<PhoneDirectoryEntry>>;
};

export type PhoneDirectoryEntry = {
  __typename?: 'PhoneDirectoryEntry';
  id: Scalars['ID'];
  phoneDirectoryId?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  numberFormatted?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type MetricsCAResult = {
  __typename?: 'MetricsCAResult';
  total: Scalars['Float'];
  extra: Scalars['Float'];
  ordered: Scalars['Float'];
};

export type Notification = {
  __typename?: 'Notification';
  id: Scalars['ID'];
  status: Scalars['String'];
  type: Scalars['String'];
  datas: Scalars['JSONObject'];
  date: Scalars['Date'];
  User: Scalars['String'];
};

export type ClusterProvisioningTaskList = {
  __typename?: 'ClusterProvisioningTaskList';
  nodes: Array<ClusterProvisioningTask>;
  cursor: Cursor;
  input: Scalars['JSONObject'];
};

export type ClusterProvisioningTask = {
  __typename?: 'ClusterProvisioningTask';
  id: Scalars['ID'];
  clusterConfigId: Scalars['String'];
  serverId: Scalars['String'];
  clusterRoleType: Scalars['String'];
  type: Scalars['String'];
  priority: Scalars['String'];
  serial: Scalars['Float'];
  status: ClusterProvisioningTaskStatusEnum;
  lastError?: Maybe<Scalars['String']>;
  payload?: Maybe<Scalars['JSONObject']>;
  Server?: Maybe<Server>;
};

export enum ClusterProvisioningTaskStatusEnum {
  TODO = 'TODO',
  RUNNING = 'RUNNING',
  DONE = 'DONE',
  ERROR = 'ERROR'
}

export type ClusterProvisioningTaskListInput = {
  orderField: Scalars['String'];
  orderDirection: Scalars['String'];
  status?: InputMaybe<Array<Scalars['String']>>;
};

export type CertificatePEMInput = {
  certPEM: Scalars['String'];
  privateKeyPEM: Scalars['String'];
  publicKeyPEM: Scalars['String'];
};

export type TransactionListResult = {
  __typename?: 'TransactionListResult';
  nodes?: Maybe<Array<Transaction>>;
  cursor?: Maybe<Cursor>;
  input?: Maybe<Scalars['JSONObject']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['ID'];
  tinkUserId?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Date']>;
  metaDate?: Maybe<Scalars['Date']>;
  value?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  providerId?: Maybe<Scalars['String']>;
  providerAccountId?: Maybe<Scalars['String']>;
  TransactionTag?: Maybe<Array<TransactionTag>>;
};

export type TransactionTag = {
  __typename?: 'TransactionTag';
  id: Scalars['ID'];
  intermediate?: Maybe<Scalars['Boolean']>;
  tagId?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  Tag?: Maybe<Tag>;
};

export type Tag = {
  __typename?: 'Tag';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['String']>;
  parentFullPath?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  Children?: Maybe<Array<Tag>>;
};

export type TransactionListInput = {
  orderField?: InputMaybe<Scalars['String']>;
  orderDirection?: InputMaybe<Scalars['String']>;
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['ID'];
  hostingConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<DeviceType>;
  brand?: Maybe<DeviceBrand>;
  model?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
  monitoringActive?: Maybe<Scalars['Boolean']>;
  options?: Maybe<Scalars['JSONObject']>;
  status?: Maybe<Scalars['Boolean']>;
};

export enum DeviceType {
  TYPE_SWITCH = 'TYPE_SWITCH',
  TYPE_ROUTER = 'TYPE_ROUTER',
  TYPE_AP = 'TYPE_AP',
  TYPE_SERVER = 'TYPE_SERVER'
}

export enum DeviceBrand {
  BRAND_CISCO = 'BRAND_CISCO',
  BRAND_UNIFI = 'BRAND_UNIFI',
  BRAND_TP_LINK = 'BRAND_TP_LINK',
  BRAND_D_LINK = 'BRAND_D_LINK',
  BRAND_NETGEAR = 'BRAND_NETGEAR',
  BRAND_OTHER = 'BRAND_OTHER'
}

export type PhoneDirectoryEntryList = {
  __typename?: 'PhoneDirectoryEntryList';
  nodes?: Maybe<Array<PhoneDirectoryEntry>>;
  cursor?: Maybe<Cursor>;
  input?: Maybe<Scalars['JSONObject']>;
};

export type PhoneDirectoryEntryListInput = {
  sortField?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<Scalars['String']>;
};

export type VideoConfig = {
  __typename?: 'VideoConfig';
  id: Scalars['ID'];
  token?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  storageDir?: Maybe<Scalars['String']>;
  CameraList?: Maybe<Array<Camera>>;
};

export type Camera = {
  __typename?: 'Camera';
  id: Scalars['ID'];
  videoConfigId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Float']>;
  protocol?: Maybe<CameraProtocolEnum>;
  retentionDays?: Maybe<Scalars['Float']>;
  motionDetectThresold?: Maybe<Scalars['Float']>;
  codec?: Maybe<CameraCodecEnum>;
  codecAudio?: Maybe<CameraAudioCodecEnum>;
  streamingType?: Maybe<CameraStreamingTypeEnum>;
  VideoConfig: VideoConfig;
  Runner: Runner;
};

export enum CameraProtocolEnum {
  PROTOCOL_RTP = 'PROTOCOL_RTP',
  PROTOCOL_RTSP = 'PROTOCOL_RTSP'
}

export enum CameraCodecEnum {
  CODEC_HEVC = 'CODEC_HEVC',
  CODEC_H264 = 'CODEC_H264'
}

export enum CameraAudioCodecEnum {
  CODEC_COPY = 'CODEC_COPY',
  CODEC_NO_AUDIO = 'CODEC_NO_AUDIO',
  CODEC_TRANSCODE_ACC = 'CODEC_TRANSCODE_ACC'
}

export enum CameraStreamingTypeEnum {
  HLS = 'HLS',
  DASH = 'DASH'
}

export type Runner = {
  __typename?: 'Runner';
  id: Scalars['ID'];
  cameraId?: Maybe<Scalars['String']>;
  pid?: Maybe<Scalars['Float']>;
  startAttempt?: Maybe<Scalars['Float']>;
  status?: Maybe<RunnerStatusEnum>;
  statusExpected?: Maybe<RunnerStatusEnum>;
};

export enum RunnerStatusEnum {
  STATUS_RUNNING = 'STATUS_RUNNING',
  STATUS_STOPPED = 'STATUS_STOPPED',
  STATUS_ERROR = 'STATUS_ERROR'
}

export type Mutation = {
  __typename?: 'Mutation';
  search: Array<SearchResult>;
  userLogin: LoginToken;
  userTest: Scalars['JSONObject'];
  userImpersonate: User;
  userStopImpersonation: Scalars['Boolean'];
  userLoginByToken: LoginToken;
  userAdd: User;
  userUpdate: User;
  userLoginByGoogleToken?: Maybe<LoginToken>;
  userProfileUpdate?: Maybe<User>;
  pictureAdd?: Maybe<Scalars['JSONObject']>;
  guestAdd?: Maybe<Guest>;
  customerAdd: Customer;
  customerUpdate: Customer;
  customerDelete: Scalars['Boolean'];
  orderAdd: Order;
  orderUpdate: Order;
  orderDelete: Order;
  orderBillByOrderIdAndDate: Order;
  paymentInsert: Payment;
  paymentConfirmIntent: Payment;
  invoiceAdd: Invoice;
  invoiceUpdate: Invoice;
  invoicePrint: Scalars['JSONObject'];
  invoiceDelete: Scalars['Boolean'];
  invoiceCharge: Invoice;
  invoiceExport: Scalars['Boolean'];
  invoiceExportAll: Scalars['Boolean'];
  contractAdd: Contract;
  orderLineSetEnd: OrderLine;
  phoneClic2Call?: Maybe<Scalars['Boolean']>;
  phoneDirectCall?: Maybe<Scalars['Boolean']>;
  phoneDirectCallByChannel?: Maybe<Scalars['Boolean']>;
  asteriskConfigAddByPhoneConfigPopulate: Scalars['JSONObject'];
  phoneConfigEdit: PhoneConfig;
  phoneConfigUpdateByIdAndToken: PhoneConfig;
  phoneHardAdd: PhoneHard;
  phoneHardUpdate: PhoneHard;
  phoneHardRemapKeys: Scalars['Boolean'];
  itemAdd: Item;
  itemUpdate: Item;
  itemDelete: Scalars['Boolean'];
  notificationQuestionAnswer: Notification;
  cardAdd: Card;
  cardDelete: Scalars['Boolean'];
  contactAdd: Contact;
  contactUpdate?: Maybe<Contact>;
  contactDelete?: Maybe<Scalars['Boolean']>;
  billingOperationLaunch?: Maybe<Scalars['Boolean']>;
  billingMakePaymentByDate?: Maybe<Scalars['Boolean']>;
  bankAccountAdd: BankAccount;
  bankAccountDelete: Scalars['Boolean'];
  serverAdd: Server;
  serverUpdate: Server;
  serverDelete: Scalars['Boolean'];
  serverUpdateAllSystem?: Maybe<Scalars['Boolean']>;
  serverUpdatePython?: Maybe<Scalars['Boolean']>;
  serverCleanAll?: Maybe<Scalars['Boolean']>;
  serverUpdateBackupByIdAndToken: Scalars['Boolean'];
  serverHandleRotation: Scalars['Boolean'];
  clusterProvisioningTaskHandleServerAdd: Server;
  serverHandleBackupRotation: Scalars['Boolean'];
  serverTriggerBackup: Server;
  hostingConfigAdd?: Maybe<HostingConfig>;
  hostingConfigUpdate?: Maybe<HostingConfig>;
  firewallUpdate: Firewall;
  backupConfigUpdate: BackupConfig;
  phoneActionClic2Call?: Maybe<Scalars['Boolean']>;
  clusterAdd: Cluster;
  clusterUpdate: Cluster;
  clusterDelete: Scalars['Boolean'];
  clusterCertUpdate: Cluster;
  clusterProvStatus: Scalars['Boolean'];
  clusterAgentActionPush?: Maybe<Scalars['Boolean']>;
  clusterRoleAdd: ClusterRole;
  clusterRoleUpdate: ClusterRole;
  clusterProvisioningTaskRun: ClusterProvisioningTask;
  clusterProvisioningTaskDeleteByid: Scalars['Boolean'];
  dnsRecordAdd?: Maybe<DnsRecord>;
  dnsRecordAddOrUpdateByFullPath?: Maybe<DnsRecord>;
  dnsRecordEdit?: Maybe<DnsRecord>;
  dnsRecordTest?: Maybe<Scalars['JSONObject']>;
  tinkUserEnroll?: Maybe<TinkUser>;
  tinkTransactionImport?: Maybe<Scalars['JSONObject']>;
  transactionImport?: Maybe<Scalars['JSONObject']>;
  transactionTagAdd?: Maybe<TransactionTag>;
  transactionTagDelete?: Maybe<Scalars['Boolean']>;
  transactionTagRemap?: Maybe<Scalars['JSONObject']>;
  tagAdd?: Maybe<Tag>;
  tagEdit?: Maybe<Tag>;
  tagDelete?: Maybe<Scalars['Boolean']>;
  tagMove?: Maybe<Tag>;
  tagRebuild?: Maybe<Tag>;
  deviceUpdateByIdAndHostingConfigIdAndToken?: Maybe<Device>;
  deviceAddByHostingConfigIdAndToken?: Maybe<Device>;
  deviceDeleteByIdAndHostingConfigIdAndToken?: Maybe<Scalars['Boolean']>;
  updateLogLevel?: Maybe<Scalars['Boolean']>;
  antiFloodGetToken?: Maybe<AntiFlood>;
  phoneDirectoryEntryAddByPhoneConfigIdAndToken?: Maybe<PhoneDirectoryEntry>;
  phoneDirectoryEntryUpdateByPhoneConfigIdAndToken?: Maybe<PhoneDirectoryEntry>;
  phoneDirectoryEntryDeleteByPhoneConfigIdAndToken?: Maybe<Scalars['Boolean']>;
  phoneDirectorySync?: Maybe<Scalars['Boolean']>;
  videoConfigAdd: VideoConfig;
  videoConfigUpdateByIdAndToken: VideoConfig;
  videoConfigUpdate: VideoConfig;
  runnerStart?: Maybe<Runner>;
  runnerStop?: Maybe<Runner>;
  runnerHandleRetention?: Maybe<Scalars['Boolean']>;
};


export type MutationsearchArgs = {
  text: Scalars['String'];
};


export type MutationuserLoginArgs = {
  input: UserLoginInput;
};


export type MutationuserImpersonateArgs = {
  id: Scalars['String'];
};


export type MutationuserLoginByTokenArgs = {
  input: LoginTokenInput;
};


export type MutationuserAddArgs = {
  input: UserInput;
};


export type MutationuserUpdateArgs = {
  input: UserInput;
  id: Scalars['String'];
};


export type MutationuserLoginByGoogleTokenArgs = {
  token: Scalars['String'];
};


export type MutationuserProfileUpdateArgs = {
  input: ProfileInput;
};


export type MutationpictureAddArgs = {
  input: PictureInput;
};


export type MutationguestAddArgs = {
  input: GuestInput;
};


export type MutationcustomerAddArgs = {
  input: CustomerInput;
};


export type MutationcustomerUpdateArgs = {
  input: CustomerInput;
  id: Scalars['String'];
};


export type MutationcustomerDeleteArgs = {
  id: Scalars['String'];
};


export type MutationorderAddArgs = {
  input: OrderInput;
};


export type MutationorderUpdateArgs = {
  input: OrderInput;
  id: Scalars['String'];
};


export type MutationorderDeleteArgs = {
  id: Scalars['String'];
};


export type MutationorderBillByOrderIdAndDateArgs = {
  date: Scalars['Date'];
  id: Scalars['String'];
};


export type MutationpaymentInsertArgs = {
  input: PaymentInput;
};


export type MutationpaymentConfirmIntentArgs = {
  paymentId: Scalars['String'];
};


export type MutationinvoiceAddArgs = {
  input: InvoiceInput;
};


export type MutationinvoiceUpdateArgs = {
  id: Scalars['String'];
  input: InvoiceInput;
};


export type MutationinvoicePrintArgs = {
  id: Scalars['String'];
};


export type MutationinvoiceDeleteArgs = {
  id: Scalars['String'];
};


export type MutationinvoiceChargeArgs = {
  id: Scalars['String'];
};


export type MutationinvoiceExportArgs = {
  year: Scalars['Float'];
};


export type MutationcontractAddArgs = {
  input: ContractInput;
};


export type MutationorderLineSetEndArgs = {
  dateEnd: Scalars['Date'];
  id: Scalars['String'];
};


export type MutationphoneClic2CallArgs = {
  variables?: InputMaybe<Scalars['JSONObject']>;
  number: Scalars['String'];
  countryCode: Scalars['String'];
  sequenceId: Scalars['String'];
  phoneConfigId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationphoneDirectCallArgs = {
  variables?: InputMaybe<Scalars['JSONObject']>;
  number: Scalars['String'];
  countryCode: Scalars['String'];
  peerName: Scalars['String'];
  phoneConfigId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationphoneDirectCallByChannelArgs = {
  variables?: InputMaybe<Scalars['JSONObject']>;
  number: Scalars['String'];
  countryCode: Scalars['String'];
  channel: Scalars['String'];
  phoneConfigId: Scalars['String'];
  token: Scalars['String'];
};


export type MutationphoneConfigEditArgs = {
  input: PhoneConfigEditInput;
  id: Scalars['String'];
};


export type MutationphoneConfigUpdateByIdAndTokenArgs = {
  input: PhoneConfigInput;
  token: Scalars['String'];
  id: Scalars['String'];
};


export type MutationphoneHardAddArgs = {
  input: PhoneHardInput;
};


export type MutationphoneHardUpdateArgs = {
  phoneHardId: Scalars['String'];
  input: PhoneHardInput;
};


export type MutationitemAddArgs = {
  input: ItemInput;
};


export type MutationitemUpdateArgs = {
  input: ItemInput;
  id: Scalars['ID'];
};


export type MutationitemDeleteArgs = {
  id: Scalars['ID'];
};


export type MutationnotificationQuestionAnswerArgs = {
  value: Scalars['String'];
  id: Scalars['ID'];
};


export type MutationcardAddArgs = {
  input: CardInput;
};


export type MutationcardDeleteArgs = {
  cardId: Scalars['String'];
};


export type MutationcontactAddArgs = {
  customerId: Scalars['String'];
  input: ContactInput;
};


export type MutationcontactUpdateArgs = {
  input: ContactInput;
  id: Scalars['String'];
};


export type MutationcontactDeleteArgs = {
  id: Scalars['String'];
};


export type MutationbillingMakePaymentByDateArgs = {
  date: Scalars['Date'];
};


export type MutationbankAccountAddArgs = {
  input: BankAccountInput;
};


export type MutationbankAccountDeleteArgs = {
  bankAccountId: Scalars['String'];
};


export type MutationserverAddArgs = {
  input: ServerInput;
};


export type MutationserverUpdateArgs = {
  input: ServerInput;
  id: Scalars['String'];
};


export type MutationserverDeleteArgs = {
  id: Scalars['String'];
};


export type MutationserverUpdateBackupByIdAndTokenArgs = {
  id: Scalars['String'];
  serverToken: Scalars['String'];
};


export type MutationserverHandleRotationArgs = {
  id: Scalars['String'];
};


export type MutationclusterProvisioningTaskHandleServerAddArgs = {
  id: Scalars['String'];
};


export type MutationserverTriggerBackupArgs = {
  id: Scalars['String'];
};


export type MutationhostingConfigAddArgs = {
  input: HostingConfigInput;
};


export type MutationhostingConfigUpdateArgs = {
  id: Scalars['String'];
  input: HostingConfigInput;
};


export type MutationfirewallUpdateArgs = {
  input: Array<FirewallRuleInput>;
  id: Scalars['String'];
};


export type MutationbackupConfigUpdateArgs = {
  id: Scalars['String'];
  input: BackupConfigInput;
};


export type MutationphoneActionClic2CallArgs = {
  variables?: InputMaybe<Scalars['JSONObject']>;
  number: Scalars['String'];
  countryCode: Scalars['String'];
  scenarioId: Scalars['String'];
  phoneConfigId: Scalars['String'];
};


export type MutationclusterAddArgs = {
  hostingConfigId: Scalars['String'];
  input: ClusterInput;
};


export type MutationclusterUpdateArgs = {
  input: ClusterInput;
  id: Scalars['String'];
};


export type MutationclusterDeleteArgs = {
  id: Scalars['String'];
};


export type MutationclusterCertUpdateArgs = {
  id: Scalars['String'];
};


export type MutationclusterProvStatusArgs = {
  id: Scalars['String'];
};


export type MutationclusterAgentActionPushArgs = {
  hostname: Scalars['String'];
  action: Scalars['String'];
  name: Scalars['String'];
};


export type MutationclusterRoleAddArgs = {
  input: ClusterRoleInput;
};


export type MutationclusterRoleUpdateArgs = {
  input: ClusterRoleInput;
  id: Scalars['String'];
};


export type MutationclusterProvisioningTaskRunArgs = {
  id: Scalars['String'];
};


export type MutationclusterProvisioningTaskDeleteByidArgs = {
  id: Scalars['String'];
};


export type MutationdnsRecordAddArgs = {
  input: DnsRecordInput;
};


export type MutationdnsRecordAddOrUpdateByFullPathArgs = {
  input: DnsRecordFullPathInput;
};


export type MutationdnsRecordEditArgs = {
  input: DnsRecordInput;
  id: Scalars['String'];
};


export type MutationtinkUserEnrollArgs = {
  externalId?: InputMaybe<Scalars['String']>;
  credentialsId: Scalars['String'];
  code?: InputMaybe<Scalars['String']>;
};


export type MutationtransactionImportArgs = {
  base64CsvFile: Scalars['String'];
};


export type MutationtransactionTagAddArgs = {
  transactionId: Scalars['String'];
  tagId: Scalars['String'];
};


export type MutationtransactionTagDeleteArgs = {
  transactionId: Scalars['String'];
  tagId: Scalars['String'];
};


export type MutationtagAddArgs = {
  input: TagInput;
};


export type MutationtagEditArgs = {
  input: TagInput;
  id: Scalars['String'];
};


export type MutationtagDeleteArgs = {
  id: Scalars['String'];
};


export type MutationtagMoveArgs = {
  parentId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationtagRebuildArgs = {
  id: Scalars['String'];
};


export type MutationdeviceUpdateByIdAndHostingConfigIdAndTokenArgs = {
  input: DeviceInput;
  token: Scalars['String'];
  hostingConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationdeviceAddByHostingConfigIdAndTokenArgs = {
  input: DeviceInput;
  token: Scalars['String'];
  hostingConfigId: Scalars['String'];
};


export type MutationdeviceDeleteByIdAndHostingConfigIdAndTokenArgs = {
  token: Scalars['String'];
  hostingConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationupdateLogLevelArgs = {
  level: Scalars['String'];
};


export type MutationphoneDirectoryEntryAddByPhoneConfigIdAndTokenArgs = {
  input: PhoneDirectoryEntryInput;
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
};


export type MutationphoneDirectoryEntryUpdateByPhoneConfigIdAndTokenArgs = {
  input: PhoneDirectoryEntryInput;
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationphoneDirectoryEntryDeleteByPhoneConfigIdAndTokenArgs = {
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type MutationphoneDirectorySyncArgs = {
  phoneConfigId: Scalars['String'];
};


export type MutationvideoConfigAddArgs = {
  input: VideoConfigInput;
};


export type MutationvideoConfigUpdateByIdAndTokenArgs = {
  input: VideoConfigInput;
  token: Scalars['String'];
  id: Scalars['String'];
};


export type MutationvideoConfigUpdateArgs = {
  input: VideoConfigInput;
  id: Scalars['String'];
};


export type MutationrunnerStartArgs = {
  id: Scalars['String'];
};


export type MutationrunnerStopArgs = {
  id: Scalars['String'];
};

export type LoginToken = {
  __typename?: 'LoginToken';
  token: Scalars['String'];
  refresh?: Maybe<Scalars['String']>;
  expiration: Scalars['Float'];
};

export type UserLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type LoginTokenInput = {
  id: Scalars['ID'];
  token: Scalars['String'];
};

export type UserInput = {
  email: Scalars['String'];
  roles: Array<UserRoleEnum>;
  hostingConfigIds?: InputMaybe<Array<Scalars['String']>>;
  phoneConfigIds?: InputMaybe<Array<Scalars['String']>>;
};

export type ProfileInput = {
  Company: CompanyInput;
};

export type CompanyInput = {
  name?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  Address?: InputMaybe<AddressInput>;
  emailSender?: InputMaybe<Scalars['String']>;
  stripeSk?: InputMaybe<Scalars['String']>;
  stripePk?: InputMaybe<Scalars['String']>;
  stripeWhk?: InputMaybe<Scalars['String']>;
  siret?: InputMaybe<Scalars['String']>;
  logo?: InputMaybe<Scalars['String']>;
  tvaIntra?: InputMaybe<Scalars['String']>;
  Rib?: InputMaybe<RibInput>;
};

export type AddressInput = {
  name?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  zip?: InputMaybe<Scalars['String']>;
  town?: InputMaybe<Scalars['String']>;
};

export type RibInput = {
  iban: Scalars['String'];
  bic: Scalars['String'];
};

export type PictureInput = {
  category?: InputMaybe<Scalars['String']>;
  guestId?: InputMaybe<Scalars['String']>;
  base64?: InputMaybe<Scalars['String']>;
};

export type GuestInput = {
  fullName?: InputMaybe<Scalars['String']>;
};

export type CustomerInput = {
  Address?: InputMaybe<AddressInput>;
  Contact?: InputMaybe<Array<ContactInput>>;
  PaymentPref?: InputMaybe<Array<PaymentPrefInput>>;
  name: Scalars['String'];
  initials: Scalars['String'];
};

export type ContactInput = {
  id?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ContactTypeEnum>;
  title?: InputMaybe<ContactTitleEnum>;
};

export type PaymentPrefInput = {
  id?: InputMaybe<Scalars['ID']>;
  BankAccount?: InputMaybe<BankAccountInput>;
  type: PaymentPrefTypeEnum;
};

export type BankAccountInput = {
  stripeId: Scalars['String'];
  bankName: Scalars['String'];
  last4?: InputMaybe<Scalars['String']>;
  stripeMandateUrl?: InputMaybe<Scalars['String']>;
};

export type OrderInput = {
  contractId?: InputMaybe<Scalars['String']>;
  paymentPrefId?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['Date']>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  status?: InputMaybe<OrderStatusEnum>;
  billingDayOfMonth?: InputMaybe<Scalars['Int']>;
  OrderLine?: InputMaybe<Array<OrderLineInput>>;
};

export type OrderLineInput = {
  id?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['Date']>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  quantity?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  itemId?: InputMaybe<Scalars['String']>;
};

export type PaymentInput = {
  id?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  type?: InputMaybe<PaymentTypeEnum>;
  value?: InputMaybe<Scalars['Int']>;
  comment?: InputMaybe<Scalars['String']>;
};

export type InvoiceInput = {
  status?: InputMaybe<InvoiceStatusEnum>;
  contractId?: InputMaybe<Scalars['String']>;
  contactId?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['Date']>;
  InvoiceLine?: InputMaybe<Array<InvoiceLineInput>>;
  Payment?: InputMaybe<Array<PaymentInput>>;
};

export type InvoiceLineInput = {
  id?: InputMaybe<Scalars['ID']>;
  quantity?: InputMaybe<Scalars['Float']>;
  vat?: InputMaybe<Scalars['Int']>;
  priceUnit?: InputMaybe<Scalars['Int']>;
  label?: InputMaybe<Scalars['String']>;
  dateStart?: InputMaybe<Scalars['Date']>;
  dateEnd?: InputMaybe<Scalars['Date']>;
  priceBuy?: InputMaybe<Scalars['Int']>;
  itemId?: InputMaybe<Scalars['String']>;
};

export type ContractInput = {
  Customer: Scalars['String'];
};

export type PhoneConfigEditInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type PhoneConfigInput = {
  name?: InputMaybe<Scalars['String']>;
  phoneSipPeerList?: InputMaybe<Array<PhoneSipPeerInput>>;
  extensionList?: InputMaybe<Array<PhoneExtensionInput>>;
  phoneSipLineList?: InputMaybe<Array<PhoneSipLineInput>>;
  musicOnHoldList?: InputMaybe<Array<MusicOnHoldInput>>;
  phoneNumberList?: InputMaybe<Array<PhoneNumberInput>>;
  phoneScenarioList?: InputMaybe<Array<PhoneScenarioInput>>;
  phoneSoundList?: InputMaybe<Array<PhoneSoundInput>>;
  phoneHardList?: InputMaybe<Array<PhoneHardInput>>;
  phoneSequenceList?: InputMaybe<Array<PhoneSequenceInput>>;
  phoneHookList?: InputMaybe<Array<PhoneHookInput>>;
  phoneQueueList?: InputMaybe<Array<PhoneQueueInput>>;
  phoneVoicemailList?: InputMaybe<Array<VoicemailInput>>;
  phoneSwitchList?: InputMaybe<Array<PhoneSwitchInput>>;
  ivrTreeList?: InputMaybe<Array<IvrTreeInput>>;
};

export type PhoneSipPeerInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  secret?: InputMaybe<Scalars['String']>;
  enableTLS?: InputMaybe<Scalars['Boolean']>;
  legacyDialplan?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<PhoneSipPeerTypeEnum>;
  displayInDirectory?: InputMaybe<Scalars['Boolean']>;
  phoneSipLineId?: InputMaybe<Scalars['String']>;
  serverId?: InputMaybe<Scalars['String']>;
  outgoingPhoneNumberId?: InputMaybe<Scalars['String']>;
  answeringSoundId?: InputMaybe<Scalars['String']>;
  voicemailMain?: InputMaybe<Scalars['String']>;
  voicemailScenarioId?: InputMaybe<Scalars['String']>;
  voicemailPrimaryId?: InputMaybe<Scalars['String']>;
  voicemailSecondaryId?: InputMaybe<Scalars['String']>;
};

export type PhoneExtensionInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ExtensionTypeEnum>;
  number?: InputMaybe<Scalars['String']>;
  scenarioId?: InputMaybe<Scalars['String']>;
  peerId?: InputMaybe<Scalars['String']>;
  sequenceId?: InputMaybe<Scalars['String']>;
  switchId?: InputMaybe<Scalars['String']>;
  hookId?: InputMaybe<Scalars['String']>;
  skipRinging?: InputMaybe<Scalars['Boolean']>;
};

export type PhoneSipLineInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  authuser?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  secret?: InputMaybe<Scalars['String']>;
  outboundProxy?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PhoneSipLineTypeEnum>;
  registrationActive?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Float']>;
  outNumberId?: InputMaybe<Scalars['String']>;
};

export type MusicOnHoldInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  isDefault?: InputMaybe<Scalars['Boolean']>;
  phoneSoundIdList?: InputMaybe<Array<Scalars['String']>>;
};

export type PhoneNumberInput = {
  id?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  phoneSipLineId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PhoneNumberRedirectType>;
  phoneScenarioId?: InputMaybe<Scalars['String']>;
  phoneSipPeerId?: InputMaybe<Scalars['String']>;
  phoneExtensionId?: InputMaybe<Scalars['String']>;
  sequenceId?: InputMaybe<Scalars['String']>;
};

export type PhoneScenarioInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PhoneScenarioTypeEnum>;
  PhoneQueue?: InputMaybe<PhoneQueueInput>;
  IvrTree?: InputMaybe<Array<IvrTreeInput>>;
  PhoneAnswering?: InputMaybe<PhoneAnsweringInput>;
  PhoneRedirect?: InputMaybe<PhoneRedirectInput>;
  conditionalScenarioId?: InputMaybe<Scalars['String']>;
};

export type PhoneQueueInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  strategy?: InputMaybe<PhoneQueueStrategyEnum>;
  agentRingTimeout?: InputMaybe<Scalars['Float']>;
  waitingStrategy?: InputMaybe<PhoneQueuewaitingStrategyInput>;
  musicOnHoldId?: InputMaybe<Scalars['String']>;
  members?: InputMaybe<Array<PhoneQueueMemberInput>>;
  incomingSound?: InputMaybe<PhoneAnnouncementInput>;
  periodicSound?: InputMaybe<PeriodicSoundInput>;
};

export type PhoneQueuewaitingStrategyInput = {
  type?: InputMaybe<PhoneQueueWaitingStrategyEnum>;
  timeout?: InputMaybe<Scalars['Float']>;
  endAnnouncement?: InputMaybe<PhoneAnnouncementInput>;
};

export type PhoneAnnouncementInput = {
  type?: InputMaybe<PhoneAnnouncementType>;
  ttsText?: InputMaybe<Scalars['String']>;
  soundId?: InputMaybe<Scalars['String']>;
};

export type PhoneQueueMemberInput = {
  type?: InputMaybe<PhoneQueueMemberTypeEnum>;
  number?: InputMaybe<Scalars['String']>;
  peerId?: InputMaybe<Scalars['String']>;
  active?: InputMaybe<Scalars['Boolean']>;
  position?: InputMaybe<Scalars['Float']>;
  timeout?: InputMaybe<Scalars['Float']>;
};

export type PeriodicSoundInput = {
  announcement?: InputMaybe<PhoneAnnouncementInput>;
  frequency?: InputMaybe<Scalars['Float']>;
};

export type IvrTreeInput = {
  id?: InputMaybe<Scalars['String']>;
  nodeId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IvrTreeTypeEnum>;
  expanded?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<IvrTreeOptionsInput>;
  children?: InputMaybe<Array<IvrTreeInput>>;
};

export type IvrTreeOptionsInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<IvrTreeOptionType>;
  announcement?: InputMaybe<PhoneAnnouncementInput>;
  number?: InputMaybe<Scalars['String']>;
  peerId?: InputMaybe<Scalars['String']>;
  scenarioId?: InputMaybe<Scalars['String']>;
  sequenceId?: InputMaybe<Scalars['String']>;
  extensionId?: InputMaybe<Scalars['String']>;
};

export type PhoneAnsweringInput = {
  type?: InputMaybe<PhoneAnsweringTypeEnum>;
  announcement?: InputMaybe<PhoneAnnouncementInput>;
};

export type PhoneRedirectInput = {
  destType?: InputMaybe<PhoneRedirectDestTypeEnum>;
  type?: InputMaybe<PhoneRedirectTypeEnum>;
  redirectPeerId?: InputMaybe<Scalars['String']>;
  redirectPeerName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  extensionId?: InputMaybe<Scalars['String']>;
};

export type PhoneSoundInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  format?: InputMaybe<Scalars['String']>;
  base64blob?: InputMaybe<Scalars['String']>;
};

export type PhoneHardInput = {
  id?: InputMaybe<Scalars['String']>;
  mac?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<PhoneHardTypeEnum>;
  enableTLS?: InputMaybe<Scalars['Boolean']>;
  phoneSipPeerId?: InputMaybe<Scalars['String']>;
  phoneLineIdList?: InputMaybe<Array<Scalars['String']>>;
  options?: InputMaybe<PhoneHardOptionsInput>;
};

export type PhoneHardOptionsInput = {
  softKeys?: InputMaybe<Scalars['JSONObject']>;
  softKeyList?: InputMaybe<Array<PhoneHardSoftKeyInput>>;
  softKeyExpansionList?: InputMaybe<Array<PhoneHardSoftKeyInput>>;
};

export type PhoneHardSoftKeyInput = {
  index?: InputMaybe<Scalars['Float']>;
  type?: InputMaybe<PhoneHardSoftKeyTypeEnum>;
  lineIndex?: InputMaybe<Scalars['String']>;
  peer?: InputMaybe<Scalars['String']>;
  extension?: InputMaybe<Scalars['String']>;
  scenario?: InputMaybe<Scalars['String']>;
  switchId?: InputMaybe<Scalars['String']>;
  directoryEntryId?: InputMaybe<Scalars['String']>;
  label?: InputMaybe<Scalars['String']>;
};

export type PhoneSequenceInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  itemList?: InputMaybe<Array<PhoneSequenceItemInput>>;
};

export type PhoneSequenceItemInput = {
  type?: InputMaybe<PhoneSequenceItemType>;
  timeout: Scalars['Float'];
  position: Scalars['Float'];
  data: PhoneSequenceItemDataInput;
};

export type PhoneSequenceItemDataInput = {
  phoneSequence?: InputMaybe<Scalars['JSONObject']>;
  name?: InputMaybe<Scalars['String']>;
  exitOnEnd?: InputMaybe<Scalars['Boolean']>;
  queueId?: InputMaybe<Scalars['String']>;
  switchId?: InputMaybe<Scalars['String']>;
  voicemailId?: InputMaybe<Scalars['String']>;
  redirectPeerId?: InputMaybe<Scalars['String']>;
  redirect?: InputMaybe<PhoneRedirectInput>;
  musicOnHoldName?: InputMaybe<Scalars['String']>;
  ivrTreeId?: InputMaybe<Scalars['String']>;
  announcement?: InputMaybe<PhoneAnnouncementInput>;
};

export type PhoneHookInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type VoicemailInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<VoicemailTypeEnum>;
  announcement?: InputMaybe<PhoneAnnouncementInput>;
};

export type PhoneSwitchInput = {
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
};

export type ItemInput = {
  label: Scalars['String'];
  vat: Scalars['Float'];
  price: Scalars['Float'];
  priceBuy: Scalars['Float'];
};

export type CardInput = {
  stripeId: Scalars['String'];
};

export type ServerInput = {
  type?: InputMaybe<ServerTypeEnum>;
  backupTargetId?: InputMaybe<Scalars['String']>;
  hostingConfigId?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
};

export type HostingConfigInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type FirewallRuleInput = {
  id?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<FirewallRuleType>;
  from: Scalars['String'];
  port?: InputMaybe<Scalars['Int']>;
  portRangeStart?: InputMaybe<Scalars['Int']>;
  portRangeEnd?: InputMaybe<Scalars['Int']>;
  action?: InputMaybe<FirewallRuleAction>;
  position: Scalars['Int'];
  desc?: InputMaybe<Scalars['String']>;
};

export type BackupConfigInput = {
  secretKey?: InputMaybe<Scalars['String']>;
  accessKey?: InputMaybe<Scalars['String']>;
  backupOptions?: InputMaybe<BackupOptionsInput>;
};

export type BackupOptionsInput = {
  frequency?: InputMaybe<BackupFrequencyEnum>;
  dailyRetention?: InputMaybe<Scalars['Int']>;
  weeklyRetention?: InputMaybe<Scalars['Int']>;
  monthlyRetention?: InputMaybe<Scalars['Int']>;
};

export type ClusterInput = {
  name: Scalars['String'];
  ClusterConfig?: InputMaybe<Array<ClusterConfigInput>>;
};

export type ClusterConfigInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  serverId?: InputMaybe<Scalars['String']>;
  ClusterRoleConfig?: InputMaybe<Array<ClusterRoleConfigInput>>;
};

export type ClusterRoleConfigInput = {
  id?: InputMaybe<Scalars['String']>;
  clusterRoleId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['String']>;
  toDelete?: InputMaybe<Scalars['Boolean']>;
  status?: InputMaybe<ClusterRoleConfigStatusEnum>;
  options?: InputMaybe<Scalars['JSONObject']>;
  port?: InputMaybe<Scalars['Float']>;
};

export type ClusterRoleInput = {
  name?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['String']>;
  tag?: InputMaybe<Scalars['String']>;
  version?: InputMaybe<Scalars['Float']>;
  isLoadBalancerClient?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<ClusterRoleTypeEnum>;
  envVarList?: InputMaybe<Array<EnvVarInput>>;
};

export type EnvVarInput = {
  id: Scalars['Float'];
  name: Scalars['String'];
  value: Scalars['String'];
};

export type DnsRecordInput = {
  parentRecordId?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<DnsRecordTypeEnum>;
  value?: InputMaybe<Scalars['String']>;
  key?: InputMaybe<Scalars['String']>;
};

export type DnsRecordFullPathInput = {
  type?: InputMaybe<DnsRecordTypeEnum>;
  value?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
};

export type TagInput = {
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  color?: InputMaybe<Scalars['String']>;
};

export type DeviceInput = {
  type?: InputMaybe<DeviceType>;
  brand?: InputMaybe<DeviceBrand>;
  name?: InputMaybe<Scalars['String']>;
  model?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  ip?: InputMaybe<Scalars['String']>;
  monitoringActive?: InputMaybe<Scalars['Boolean']>;
  options?: InputMaybe<Scalars['JSONObject']>;
};

export type AntiFlood = {
  __typename?: 'AntiFlood';
  id: Scalars['ID'];
  serial?: Maybe<Scalars['Float']>;
  token?: Maybe<Scalars['String']>;
  ip?: Maybe<Scalars['String']>;
};

export type PhoneDirectoryEntryInput = {
  id?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  number?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
};

export type VideoConfigInput = {
  id?: InputMaybe<Scalars['ID']>;
  storageDir?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  CameraList?: InputMaybe<Array<CameraInput>>;
};

export type CameraInput = {
  id?: InputMaybe<Scalars['ID']>;
  videoConfigId?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  host?: InputMaybe<Scalars['String']>;
  path?: InputMaybe<Scalars['String']>;
  port?: InputMaybe<Scalars['Float']>;
  protocol?: InputMaybe<CameraProtocolEnum>;
  codec?: InputMaybe<CameraCodecEnum>;
  codecAudio?: InputMaybe<CameraAudioCodecEnum>;
  motionDetectThresold?: InputMaybe<Scalars['Float']>;
  retentionDays?: InputMaybe<Scalars['Float']>;
  streamingType?: InputMaybe<CameraStreamingTypeEnum>;
};

export type Subscription = {
  __typename?: 'Subscription';
  subHello: Scalars['JSONObject'];
  phoneCallUpdateById?: Maybe<PhoneCall>;
  phoneCallUpdate?: Maybe<PhoneCall>;
  phoneCallAdd?: Maybe<PhoneCall>;
  PhoneEvent: Scalars['JSONObject'];
  phoneConfigUpdate?: Maybe<PhoneConfig>;
  phoneHardUpdate?: Maybe<PhoneHard>;
  serverUpdateByIdAndToken: Server;
  serverTriggerBackup: Server;
  clusterAgentProcessAction?: Maybe<Scalars['JSONObject']>;
  clusterProvisioningTaskUpdate: ClusterProvisioningTask;
  runnerUpdate?: Maybe<Runner>;
};


export type SubscriptionphoneCallUpdateByIdArgs = {
  phoneConfigId: Scalars['String'];
  id: Scalars['String'];
};


export type SubscriptionphoneCallUpdateArgs = {
  phoneConfigId: Scalars['String'];
};


export type SubscriptionphoneCallAddArgs = {
  phoneConfigId: Scalars['String'];
};


export type SubscriptionPhoneEventArgs = {
  token: Scalars['String'];
  phoneConfigId: Scalars['String'];
};


export type SubscriptionphoneConfigUpdateArgs = {
  id: Scalars['String'];
};


export type SubscriptionphoneHardUpdateArgs = {
  mac: Scalars['String'];
  id: Scalars['String'];
};


export type SubscriptionserverUpdateByIdAndTokenArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type SubscriptionserverTriggerBackupArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
};


export type SubscriptionclusterAgentProcessActionArgs = {
  hostname: Scalars['String'];
  name: Scalars['String'];
};


export type SubscriptionclusterProvisioningTaskUpdateArgs = {
  id: Scalars['String'];
};


export type SubscriptionrunnerUpdateArgs = {
  id: Scalars['String'];
};
