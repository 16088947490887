import { CameraListModule } from "@zipou/video_front"

type CameraListModuleDisplayProps = {
  videoConfigId: string,
  token: string,
}

export const CameraListModuleDisplay = ({ videoConfigId, token }: CameraListModuleDisplayProps) => {

  return <div>
    <CameraListModule videoConfigId={videoConfigId} token={token}
      moduleConfig={{
        url: "https://www.box4b.fr",
        wsUrl: "wss://www.box4b.fr/subscriptions",
      }}
    />
  </div>

}