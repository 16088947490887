import { useState } from "react"
// import { VideoConfigForm } from "../Form/VideoConfigForm"
import { VideoConfig, VideoConfigInput } from "model"
import { useMutation, useQuery } from "@apollo/client"
import videoConfigGetGql from "graphql/VIDEO/Config/query/videoConfigGet.gql"
import videoConfigUpdateGql from "graphql/VIDEO/Config/mutation/videoConfigUpdate.gql"
import { VideoConfigForm } from "../Form/VideoConfigForm"

type VideoConfigEditProps = {
  id: string,
  onDone?: () => void,
}

export const VideoConfigEdit = ({ id, onDone }: VideoConfigEditProps) => {

  const [videoConfig, updateVideoConfig] = useState<VideoConfigInput>()
  const [errors, updateErrors] = useState<any>()

  const { data } = useQuery<{ videoConfig: VideoConfig }>(videoConfigGetGql, {
    variables: {
      id,
    },
    skip: !id,
    onCompleted: (data) => {
      updateVideoConfig(getInput(data.videoConfig))
    }
  })

  const getInput = (videoConfig: VideoConfig): VideoConfigInput => {

    const { token, ...input } = videoConfig

    return {
      ...input,
    }
  }

  const [mutate] = useMutation(videoConfigUpdateGql)

  const onSave = () => {
    mutate({
      variables: {
        id,
        input: videoConfig,
      }
    })
      .then(() => {
        onDone && onDone()
        console.log("OK")
      })
      .catch((e) => {
        console.log("EEEEE", e)
      })
  }

  return <div>
    <VideoConfigForm videoConfig={videoConfig as VideoConfigInput} onChange={(pc) => updateVideoConfig(pc)} errors={errors} />
    <div className="mt-1">
      <button className="btn btn-sm btn-dark ml-1" onClick={() => onSave()}>Enregistrer</button>
      <button className="btn btn-sm btn-danger ml-1" onClick={() => onDone && onDone()}>Annuler</button>
    </div>
  </div>
}